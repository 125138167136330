import AccreditationClient from "@equidefi/portals/clients/AccreditationClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useMutation, useQuery } from "@tanstack/react-query";

/**
 * @param offeringId
 * @param status
 */
export const useAccreditationDocuments = (offeringId, status) => {
  const accreditationApi = useApi(AccreditationClient);

  return useQuery(
    ["accreditation-documents", offeringId],
    () => accreditationApi.getDocuments(offeringId, status),
    { enabled: !!offeringId }
  );
};

/**
 * @param id
 * @param options
 */
export const useVaultRecord = (id, options = {}) => {
  const accreditationApi = useApi(AccreditationClient);

  return useQuery(
    ["vault-record", id],
    async () => accreditationApi.getVaultRecord(id),
    {
      refetchOnWindowFocus: false,
      stale: true,
      manual: false,
      enabled: true,
      retry: false,
      ...options,
    }
  );
};

/**
 * @param {string} accreditationId
 * @param {object} options
 */
export const useAccept = (accreditationId, options) => {
  const accreditationApi = useApi(AccreditationClient);

  return useMutation(
    async ({ file }) => accreditationApi.accept(accreditationId, file),
    options
  );
};

/**
 * @param accreditationId
 * @param options
 */
export const useReject = (accreditationId, options) => {
  const accreditationApi = useApi(AccreditationClient);

  return useMutation(
    async ({ file, reason }) =>
      accreditationApi.reject(accreditationId, file, reason),
    options
  );
};
