import type { Recipients } from "docusign-esign";

type SignerRole = "issuer" | "investor" | string;
type SignerSummaryData = {
  signed: boolean;
  status: string;
  email: string;
  name: string;
  order: string;
  role: SignerRole;
  [key: string]: unknown;
};

export const signerMap = (
  recipients: Recipients
): Map<SignerRole, SignerSummaryData> => {
  const signers = recipients?.signers ?? [];
  if (signers.length === 0) return new Map();

  return new Map(
    signers.map((s) => {
      const role: SignerRole = s.roleName ?? "unknown";

      return [
        role,
        {
          role,
          id: s.recipientIdGuid,
          signed: s?.status?.toLowerCase() === "completed",
          name: s.name,
          order: s.routingOrder,
          email: s.email,
          status: s?.status?.toLowerCase(),
        } as SignerSummaryData,
      ];
    })
  );
};

export const allRecipientsSigned = (recipients: Recipients): boolean => {
  const map = signerMap(recipients);

  return (
    Array.from(map.entries()).filter(([, signer]) => signer.signed).length ===
    map.size
  );
};

export const hasIssuerCountersigned = (recipients: Recipients): boolean => {
  const map = signerMap(recipients);

  return map.get("issuer")?.signed ?? false;
};

export const allInvestorsSigned = (recipients: Recipients): boolean => {
  const map = signerMap(recipients);

  const investors = Array.from(map.entries()).filter(
    ([role]) => role !== "issuer"
  );

  return (
    investors.filter(([, signer]) => signer.signed).length === investors.length
  );
};
