import { Button, useToast } from "@chakra-ui/react";

import { useStartSignatureRequest } from "@equidefi/portals/hooks/useSignatures";

const SetupAgreementButton = ({
  investmentId,
  onSetup = () => {},
  ...rest
}) => {
  const setupAgreement = useStartSignatureRequest();
  const toast = useToast();

  const handleConfirmSetup = async ({ reason }) => {
    try {
      await setupAgreement.mutateAsync({ investmentId, manualSetup: true });
      toast({
        status: "success",
        description: `The investment agreement has been setup for the investor.`,
      });
      onSetup();
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description:
          e.response?.data?.errors?.join(", ") ??
          "Sorry, we could not setup this agreement. Our team has been notified.",
      });
    }
  };

  if (!investmentId) {
    return null;
  }

  return (
    <Button
      isLoading={setupAgreement.isLoading}
      onClick={handleConfirmSetup}
      colorScheme="purple"
      {...rest}
    >
      Setup Agreement
    </Button>
  );
};

export default SetupAgreementButton;
