import clsx from "clsx";
import React from "react";

export const SpinnerIcon = ({ className = null, children }) => {
  const classes = clsx(
    "spinner-border",
    "text-primary",
    ...(Array.isArray(className) ? className : [className])
  );
  return (
    <div className={classes} role="status">
      <span className="visually-hidden">{children}</span>
    </div>
  );
};

const Spinner = ({ show, spinnerClasses = null, children }) => {
  return (
    <>
      {show ? (
        // <div
        //   className="d-flex align-items-center justify-content-center"
        //   style={{ height: "70vh" }}
        // >
        <div className="text-center p-4 " style={{ height: "auto" }}>
          <SpinnerIcon className={spinnerClasses} />
        </div>
      ) : (
        // </div>
        <>{children}</>
      )}
    </>
  );
};

export default Spinner;
