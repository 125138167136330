import { ApiClient } from "./ApiClient";

class IssuerClient extends ApiClient {
  async listRoles(issuerId) {
    const response = await this.get(`/v2/issuers/roles/${issuerId}`);
    return response.data;
  }

  async createRole(issuerId: string, name: string) {
    const response = await this.post(`/v2/issuers/role/${issuerId}`, {
      name,
    });
    return response.data;
  }

  async updateSignatory(issuerId: string, signatoryId: string) {
    const response = await this.patch(`/v2/issuers/${issuerId}/signatory`, {
      signatory_id: signatoryId,
    });
    return response.data;
  }

  async updateRole(issuerId: string, roleId: string, name: string) {
    const response = await this.put(`/v2/issuers/role/${issuerId}/${roleId}`, {
      name,
    });
    return response.data;
  }

  async users(issuerId: string) {
    const response = await this.get(`/v2/issuers/${issuerId}/users`);
    return response.data;
  }

  async deleteRole(issuerId: string, roleId: string) {
    const response = await this.delete(
      `/v2/issuers/role/${issuerId}/${roleId}`
    );
    return response.data;
  }

  async updateUserRole(
    issuerId: string,
    userId: string,
    data: Record<any, unknown>
  ) {
    const response = await this.put(
      `/v2/issuers/${issuerId}/user/${userId}`,
      data
    );
    return response.data;
  }

  async removeUserRole(issuerId: string, userId: string) {
    const response = await this.put(`/v2/issuers/role/${issuerId}/${userId}`);
    return response.data;
  }
}

export default IssuerClient;
