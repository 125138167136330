import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { Center, Container, Spinner, useToast } from "@chakra-ui/react";
import UserToken from "@equidefi/portals/clients/UserToken";
import { useRegister } from "@equidefi/portals/hooks/useAuthentication";

import { Heading } from "@equidefi/ui";
import InviteUserForm from "../../../components/users/InviteUserForm";
import { useVerifyInvite } from "../../../hooks/useInvitations";

const AcceptInvitation = () => {
  const { token: inviteToken } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data = {}, isLoading } = useVerifyInvite(inviteToken);
  let { invitation, token: userToken } = data;
  const toast = useToast();
  const register = useRegister("issuer", inviteToken);

  const onSubmit = async (values) => {
    try {
      const token = await register.mutateAsync({
        first_name: values.first_name,
        middle_name: values.middle_name === "" ? null : values.middle_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        title: values.title === "" ? null : values.title,
        company: values.company === "" ? null : values.company,
        entity_type: "INDIVIDUAL",
      });
      onComplete(values, token);
    } catch (e) {
      console.error(e);
      toast({ status: "error", description: "User is already registered" });
    }
  };

  const onComplete = (user, token) => {
    dispatch({ type: "token", payload: token });
    dispatch({
      type: "user",
      payload: {
        ...user,
        token: token,
      },
    });
    UserToken.setToken(token);

    history.push(`/offering`);
  };

  useEffect(() => {
    if (invitation?.user && userToken) {
      onComplete(invitation.user, userToken);
    }
  }, [invitation, userToken]);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  if (!invitation) {
    toast({ status: "warning", description: "Your invitation is invalid" });
    return <Redirect to="/" />;
  }

  return (
    <Container minW="full" py="1.5rem" px="2.25rem">
      <Heading fontWeight="bold" textStyle="h1">
        Accept Invitation
      </Heading>
      <InviteUserForm
        isLoading={isLoading}
        invitation={invitation}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default AcceptInvitation;
