/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import axios from "axios";
import { Formik } from "formik";
import FormControl from "../../components/formControl";
import Spinner from "../../components/spinner";
import { completeProfileFormSchema } from "../../constants/forms";
import { getUser, updatePassword, updateUser } from "../../helpers";

const CompleteProfile = (props) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${props.match.params.token}`;

  const dispatch = useDispatch();

  const { data: me } = useCurrentUser();
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getUser().then((user) => dispatch({ type: "user", payload: user }));
  }, []);

  const handleSubmit = async (form) => {
    setSpinner(true);

    const request = {
      email: form.email.toLowerCase().trim(),
      name: form.name,
      phone: form.phone,
      company: form.company,
      title: form.title,
      active: true,
    };

    updateUser(request).then((_) => {
      updatePassword(me.id, form.password, props.match.params.token).then(
        (_) => {
          toast.success(
            `Thank you for completing your profile! Please login to continue`
          );
          setSpinner(false);
          props.history.push("/");
        }
      );
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-5 col-lg-6  col-xl-4 px-lg-6 my-5 align-self-center">
          <h1 className="display-4 text-center mb-2">Complete your profile</h1>
          <hr />

          <Formik
            initialValues={me}
            validationSchema={completeProfileFormSchema}
            enableReinitialize
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="text"
                  name="name"
                  title="Name"
                  required={true}
                  value={values.name}
                  error={errors.name}
                  onChange={handleChange}
                />

                <FormControl
                  type="text"
                  name="title"
                  title="Title"
                  required={true}
                  value={values.title}
                  error={errors.title}
                  onChange={handleChange}
                />

                <FormControl
                  type="text"
                  name="company"
                  title="Company"
                  required={true}
                  value={values.company}
                  error={errors.company}
                  onChange={handleChange}
                />

                <FormControl
                  type="email"
                  name="email"
                  title="Email Address"
                  required={true}
                  disabled
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange}
                />

                <FormControl
                  type="password"
                  name="password"
                  title="New Password"
                  required={true}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                />

                <FormControl
                  type="password"
                  name="confirmPassword"
                  title="Confirm Password"
                  required={true}
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={handleChange}
                />

                <Spinner show={spinner}>
                  <button
                    type="submit"
                    form="profile-form"
                    className="btn btn-eq-primary w-100"
                    onClick={handleSubmit}
                  >
                    Continue
                  </button>
                </Spinner>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(/img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
