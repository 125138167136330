import { useState } from "react";
import Select from "react-select";

import GoogleClient from "@equidefi/portals/clients/GoogleClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

import states from "../../data/states.json";
import { useGoogleAddressSearch } from "../../hooks/useAddressSearch";
import useDebounce from "../../hooks/useDebounce";
import FormControl from "../formControl";

const GooglePlaceSearch = ({ onSelected }) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);
  const { data: options, isFetching } = useGoogleAddressSearch(
    `${searchVal}&components=country:us`
  );

  const googleApi = useApi(GoogleClient);
  const handleOnSelect = async (option) => {
    const data = await googleApi.place(option.value);
    onSelected(data);
  };

  return (
    <div className="address border-bottom border-gray-300">
      <Select
        autoFocus={false}
        placeholder={"Search address (US only)"}
        noOptionsMessage={() => "No addresses found"}
        filterOption={null}
        onInputChange={(value) => {
          setInputSearchValue(value);
          return value;
        }}
        onChange={handleOnSelect}
        isLoading={isFetching}
        options={options}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </div>
  );
};

export const AddressAutocomplete = ({
  setValues,
  values,
  errors,
  handleChange,
  addressName,
}) => {
  return (
    <>
      <GooglePlaceSearch
        onSelected={(address) => {
          setValues({
            ...values,
            [addressName]: {
              line_1: address.street,
              city: address.city,
              state: address.state,
              postal_code: address.zipcode,
            },
          });
        }}
      />
      <br />

      <FormControl
        type="text"
        name={`${addressName}[line_1]`}
        title="Address"
        placeholder="e.g. 100 Main St"
        value={values[addressName]?.line_1}
        error={errors[addressName]?.line_1}
        onChange={handleChange}
        required
      />
      <div className="row">
        <div className="col">
          <FormControl
            type="text"
            name={`${addressName}[city]`}
            title="City"
            placeholder="e.g. New York City"
            value={values[addressName]?.city}
            error={errors[addressName]?.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col">
          <FormControl
            type="select"
            name={`${addressName}[state]`}
            title="State"
            options={states}
            value={states.find((x) =>
              [x.value, x.id, x.shortCode, x.label]
                .map((v) => v.toLowerCase())
                .includes(values?.[addressName]?.state?.toLowerCase())
            )}
            required
            error={errors?.[addressName]?.state}
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <FormControl
            type="postalcode"
            name={`${addressName}[postal_code]`}
            title="Postal Code"
            placeholder="e.g. 21044"
            value={values[addressName]?.postal_code}
            error={errors[addressName]?.postal_code}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </>
  );
};
