/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Swal from "sweetalert";

import {
  IconButton,
  LinkBox,
  LinkOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";

import OfferingStatusBadge from "../../components/OfferingStatusBadge";
import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import FloatingSearch from "../../components/floating-search";
import { isAdmin, stockTypeOfferingPage } from "../../helpers";
import useDebounce from "../../hooks/useDebounce";
import { useOfferings } from "../../hooks/useOfferings";

const OfferingList = (props) => {
  const [inputSearchValue, setSearchVal] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);
  const toast = useToast();

  const {
    data: offerings,
    refetch,
    isLoading: spinner,
  } = useOfferings(searchVal);

  const offeringApi = useApi(OfferingClient);
  const { data: me } = useCurrentUser();

  const deleteOffering = async (id) => {
    // API
    const status = await Swal({
      title: `Delete`,
      text: `Are you sure you want to delete it?`,
      buttons: ["Cancel", "Delete"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await offeringApi.remove(id);
      toast({ status: "success", description: "Offering has been deleted" });
      refetch();
    } catch (e) {
      toast({
        status: "error",
        description: "Something went wrong, please try again later.",
      });
    }
  };

  return (
    <>
      <Header name="Offerings">
        {isAdmin(me) && (
          <Link to="/offering/create" className="btn btn-eq-primary">
            New Offering
          </Link>
        )}
      </Header>
      <div className="container-fluid">
        <FloatingSearch
          placeholder="Search by name, description, type..."
          id="search-offerings"
          label="Search Offerings"
          onChange={(e) => setSearchVal(e.target.value)}
        />

        <div className="card">
          <Spinner show={spinner}>
            <TableContainer>
              <Table className="table table-nowrap table-hover mb-0">
                <Thead>
                  <Tr>
                    <Th>Offering</Th>
                    <Th>Type</Th>
                    <Th>Launch Date</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                    {isAdmin(me) && <Th className="">Action</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {offerings?.map((offering) => {
                    return (
                      <Tr key={offering.id}>
                        <LinkBox as={Td}>
                          <Tooltip
                            label={offering.name}
                            openDelay={1000}
                            placement="auto"
                          >
                            <Text
                              mb={0}
                              maxWidth={{ base: "35vw", md: "25em" }}
                              isTruncated
                            >
                              <LinkOverlay
                                as={Link}
                                to={`/offering/${offering.id}/overview`}
                              >
                                {offering.name}
                              </LinkOverlay>
                            </Text>
                          </Tooltip>
                          <Text as="span" className="small text-muted">
                            {offering.issuer?.name}
                          </Text>
                        </LinkBox>
                        <Td>{stockTypeOfferingPage(offering?.stock_type)}</Td>
                        <Td>
                          {offering.launch_date &&
                            moment(offering.launch_date).format("ll")}
                        </Td>
                        <Td>
                          <NumberFormat
                            value={offering.total_amount}
                            displayType="text"
                            thousandSeparator
                            decimalScale={0}
                            prefix="$"
                          />
                        </Td>
                        <Td>
                          <OfferingStatusBadge
                            status={offering.status}
                            fontSize=".8em"
                          />
                        </Td>
                        {isAdmin(me) && (
                          <Td>
                            {(offering.status === "Draft" ||
                              offering.status === "In Review") && (
                              <Tooltip placement="auto" label="Delete">
                                <IconButton
                                  size="sm"
                                  colorScheme="gray"
                                  aria-label="Delete"
                                  icon={<i className="fe fe-trash" />}
                                  onClick={() => deleteOffering(offering.id)}
                                ></IconButton>
                              </Tooltip>
                            )}
                          </Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Spinner>
        </div>
      </div>
    </>
  );
};

export default OfferingList;
