import { createStore } from "redux";

const initial_state = {
  email: "",
  user: {},
  offering: {},
  issuer: {},
  settings: {},
};

const appReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "email":
      return { ...state, email: action.payload };
    case "user":
      return { ...state, user: action.payload };
    case "offering":
      return { ...state, offering: action.payload };
    case "issuer":
      return { ...state, issuer: action.payload };
    case "settings":
      return { ...state, settings: action.payload };
    case "reset":
      return {
        ...state,
        offering: {},
        issuer: {},
      };
    default:
      return state;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("eq-issuer");
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("eq-issuer", serializedState);
  } catch (err) {
    console.log(err);
  }
};

const store = createStore(
  appReducer,
  loadState(),
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
