/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tag, useDisclosure, useToast } from "@chakra-ui/react";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment/moment";
import React, { useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { InvestmentEventHistory } from "@equidefi/portals/components/investments/EventHistory";
import { StatusBadge } from "@equidefi/portals/components/investments/StatusBadge";
import { hasEvent } from "@equidefi/portals/helpers/investments";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { useUpdateInvestment } from "@equidefi/portals/hooks/useInvestments";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { offeringIsRegA, userFullName } from "@equidefi/shared";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import InfoCard from "../../../components/card";
import InvestmentAgreements from "../../../components/investments/Agreements/Agreements";
import InvestmentSignatures from "../../../components/investments/Agreements/InvestmentSignatures";
import InvestmentAccreditation from "../../../components/investments/InvestmentAccreditation/InvestmentAccreditation";
import InvestmentInquiry from "../../../components/investments/InvestmentInquiry";
import InvestmentPayments from "../../../components/investments/InvestmentPayments";
import InvestorProfile from "../../../components/investments/InvestorProfile";
import ModalUpdateStatus from "../../../components/investments/modals/UpdateStatus";
import ModalUpdateWorkflowStep from "../../../components/investments/modals/UpdateWorkflowStep";
import Spinner from "../../../components/spinner";
import
  {
    isAdmin,
    isIssuerOwner,
    isIssuerViewer,
    isOfferingEditor,
    isOfferingOwner,
  } from "../../../helpers";
import { useInvestment, usePokeInvestor } from "../../../hooks/investments";
import { useOfferingFull } from "../../../hooks/useOfferings";
import Header from "../../../layouts/header";
import { EditAmountModal } from "./EditAmountModal";
const NO_POKE_INVESTOR = ["APPROVED", "COMPLETED", "CLOSED", "REJECTED"];

const InvestmentOverview = (props) => {
  const { id, investment_id } = useParams();
  const { data: full } = useOfferingFull(id);
  const { data: user } = useCurrentUser();

  const toast = useToast();
  const componentRef = useRef();

  const { data: investmentData, refetch: refetchInvestment } =
    useInvestment(investment_id);
  const { data: signatureData } = useInvestmentSignatures(investment_id);

  const signatureSummary = signatureData?.summary ?? {};
  const isCountersigned = signatureSummary?.isCountersigned || false;

  const updateStatusModal = useDisclosure(false);
  const updateWorkflowStep = useDisclosure(false);
  const [spinner, showSpinner] = useState(false);
  const [modalInvestorReview, showInvestorReviewModal] = useState(false);
  const amountModal = useDisclosure();

  const [editInvestment, setEditInvestment] = useState(false);
  const [reviewDescription, setReviewDescription] = useState("");

  const updateInvestment = useUpdateInvestment(investment_id, {
    onSettled: () => {
      setEditInvestment(false);
      showSpinner(false);
    },
    onSuccess: () => {
      toast({
        status: "success",
        description: `Successfully updated investment amount`,
      });
    },
    onError: () => {
      toast({
        status: "error",
        description: "Something went wrong",
      });
    },
  });

  const pokeInvestor = usePokeInvestor(investment_id, {
    onSettled: () => showSpinner(false),
    onSuccess: () => {
      toast({
        status: "success",
        description: `Sent a reminder to ${investmentData?.user?.email}`,
      });
    },
    onError: () => {
      toast({
        status: "error",
        description: "Something went wrong",
      });
    },
  });

  const canEditPayment = useMemo(
    () =>
      isAdmin(user) ||
      isOfferingOwner(user, full?.offering?.id) ||
      isIssuerOwner(user, full?.offering?.issuer_id),
    [user, full]
  );

  const canEdit = useMemo(
    () => canEditPayment || isOfferingEditor(user, full?.offering?.id),
    [user, full]
  );

  const paid = useMemo(() => {
    const succeededPayments =
      investmentData?.payments
        ?.filter((p) => p.status === "succeeded")
        ?.reduce((memo, payment) => memo + Number(payment.amount), 0) ?? 0;

    return Math.max(succeededPayments, 0);
  }, [investmentData]);

  const balance = useMemo(() => {
    if (!investmentData) return 0;

    const amount = investmentData?.approved_amount ?? investmentData?.amount;

    return Number(amount) - paid;
  }, [investmentData, paid]);

  const isRegA = useMemo(() => offeringIsRegA(full?.offering), [full]);

  const canCountersign = useMemo(() => {
    return (
      investmentData?.status !== "REJECTED" &&
      Number(balance) === 0 &&
      (isRegA
        ? true
        : hasEvent(investmentData?.event_history, "DILIGENCE", "APPROVED"))
    );
  }, [investmentData?.status, investmentData?.event_history, balance, full]);

  // Download Questionnaire
  const handlePdfDownload = () => {
    showSpinner(true);
    axios.get(`investment/${investment_id}/questionnaire`).then((response) => {
      let toBytes = new Uint8Array(response.data.data),
        toBinary = ``;
      toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));
      const file = window.btoa(toBinary);
      FileSaver.saveAs(
        `data:application/pdf;base64,${file}`,
        "investor_questionnaire.pdf"
      );
      showSpinner(false);
    });
  };

  // Print Questionnaire
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleInvestorApprove = () => {
    showSpinner(true);

    let data = {
      description: reviewDescription,
    };

    axios
      .post(`/investment/${investment_id}/status/issuer_approved`, data)
      .then((response) => {
        refetchInvestment();
        showSpinner(false);
        showInvestorReviewModal(false);
        toast({ status: "success", description: "Investor has been Approved" });
      });
  };

  const handleInvestorReject = () => {
    showSpinner(true);

    let data = {
      description: reviewDescription,
    };

    axios
      .post(`/investment/${investment_id}/status/issuer_rejected`, data)
      .then((response) => {
        refetchInvestment();
        showSpinner(false);
        showInvestorReviewModal(false);
        toast({ status: "success", description: "Investor has been Rejected" });
      });
  };

  const handleResend = async () => {
    showSpinner(true);
    await pokeInvestor.mutateAsync();
  };

  const saveEditedAmount = async (data) => {
    investmentData.approved_amount = data.amount;
    investmentData.approved_quantity = data.quantity;
    investmentData.edited = true;
    showSpinner(true);

    amountModal.onClose();
    await updateInvestment.mutateAsync({
      amount: data.amount,
      quantity: data.quantity,
    });
    setEditInvestment(false);
    showSpinner(false);
  };

  const canEditInvestmentAmount = useMemo(() => {
    return !isCountersigned && isAdmin(user);
  }, [isCountersigned, user]);

  const essa = () => {};
  return (
    <>
      <Header
        id={id}
        title={investmentData?.offering?.name}
        name={userFullName(investmentData?.user)}
      />
      <div className="container-fluid">
        <Spinner show={spinner}>
          <div className="row">
            <InfoCard
              editable={
                isIssuerOwner(user, investmentData?.issuer_id) ||
                isOfferingOwner(user, investmentData?.offering_id) ||
                isAdmin(user)
              }
              onClick={updateStatusModal.onOpen}
              title="Status"
            >
              <StatusBadge
                as={Tag}
                fontSize="lg"
                action={investmentData?.status}
              />
              <ModalUpdateStatus
                investmentId={investment_id}
                onHide={() => updateStatusModal.onClose()}
                onSubmit={() => {
                  updateStatusModal.onClose();
                  refetchInvestment();
                }}
                show={updateStatusModal.isOpen}
              />
            </InfoCard>
            {["COMPLETED", "CLOSED"].includes(investmentData?.status) ||
            !!investmentData?.offering_traunche_id ? (
              <InfoCard title="Closing Tranche">
                <div className="d-flex">
                  <Text fontSize="13px" mb="0">
                    {investmentData?.offering_traunche_id
                      ? `${moment(
                          investmentData?.offering_traunche?.finalized_date
                        ).format("MMM Do, yyyy")}`
                      : "Ready to Close"}
                  </Text>
                  {!isIssuerViewer(user, investmentData?.issuer_id) && (
                    <Link
                      to={
                        investmentData?.offering_traunche_id
                          ? `/offering/${investmentData?.offering_id}/tranches/${investmentData?.offering_traunche_id}`
                          : `/offering/${investmentData?.offering_id}/tranches`
                      }
                    >
                      <IconButton
                        icon={<Icon.ExternalLink size="1em" />}
                        color="equidefi.blue"
                        variant="unstyled"
                        aria-label="External link"
                        ml={2}
                        mb="1"
                        minWidth="auto"
                        height="auto"
                      />
                    </Link>
                  )}
                </div>
              </InfoCard>
            ) : (
              <InfoCard
                editable={
                  isIssuerOwner(user, investmentData?.issuer_id) ||
                  isOfferingOwner(user, investmentData?.offering_id) ||
                  isAdmin(user)
                }
                onClick={updateWorkflowStep.onOpen}
                title="Investment Current Step"
              >
                <Tag fontSize="lg" textTransform="uppercase" colorScheme="gray">
                  {investmentData?.next ?? "None"}
                </Tag>
                <ModalUpdateWorkflowStep
                  investmentId={investment_id}
                  onHide={() => updateWorkflowStep.onClose()}
                  onSubmit={() => {
                    updateWorkflowStep.onClose();
                    refetchInvestment();
                  }}
                  show={updateWorkflowStep.isOpen}
                />
              </InfoCard>
            )}
            <InfoCard title="Total Subscription">
              {investmentData?.approved_quantity &&
              investmentData?.approved_quantity !== investmentData?.quantity ? (
                <>
                  <span className="me-2">
                    <NumberFormat
                      value={investmentData?.approved_quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </span>
                  <del className="small text-muted">
                    <NumberFormat
                      value={investmentData?.quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </del>
                </>
              ) : (
                <NumberFormat
                  value={investmentData?.quantity}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                />
              )}
            </InfoCard>
            <InfoCard
              title="Amount"
              editable={canEditInvestmentAmount}
              onClick={() => amountModal.onOpen()}
            >
              {investmentData?.approved_amount &&
              investmentData?.amount !== investmentData?.approved_amount &&
              !editInvestment ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="me-2">
                        <NumberFormat
                          value={investmentData?.approved_amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </span>
                      <del className="small text-muted">
                        <NumberFormat
                          value={investmentData?.amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </del>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <NumberFormat
                    value={investmentData?.amount}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={0}
                    prefix="$"
                  />
                </div>
              )}
            </InfoCard>
            <InfoCard title="Total Payments">
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={paid}
                prefix="$"
              />
            </InfoCard>
            <InfoCard title="Total Due">
              <NumberFormat
                displayType="text"
                thousandSeparator
                value={balance}
                prefix="$"
              />
            </InfoCard>
          </div>

          <div className="row">
            <div className="col">
              {investmentData?.agreements ? (
                <InvestmentAgreements
                  investment={investmentData}
                  canCountersign={canCountersign}
                  canView={canEdit}
                />
              ) : (
                <InvestmentSignatures
                  investmentId={investment_id}
                  investment={investmentData}
                  isCountersignable={canCountersign}
                  onCountersign={refetchInvestment}
                />
              )}

              <InvestmentPayments
                canEdit={canEditPayment}
                canRefund={!isCountersigned && canEditPayment}
                investment={investmentData}
                offering={full?.offering}
                totalPaid={paid}
                totalBalance={balance}
                onUpdatePayments={() => {
                  refetchInvestment();
                }}
              />

              <InvestmentInquiry />
              <InvestmentAccreditation
                investment={investmentData}
                offering={full?.offering}
                user={user}
              />
            </div>

            <div className="col col-md-4">
              <InvestorProfile user={user} investment={investmentData} />
              <InvestmentEventHistory
                investment={investmentData}
                canPoke={
                  full?.offering?.status === "Active" &&
                  !NO_POKE_INVESTOR.includes(investmentData?.status) &&
                  canEdit
                }
                onPoke={() => handleResend()}
              />
            </div>
          </div>
        </Spinner>
      </div>

      <EditAmountModal
        offering={full?.offering}
        investment={investmentData}
        onSubmit={saveEditedAmount}
        modal={amountModal}
      />

      <Modal
        centered
        size="xl"
        show={modalInvestorReview}
        onHide={() => showInvestorReviewModal(false)}
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>Review Investor Questionnaire</span>{" "}
            {investmentData?.status !== "Invite Sent" && (
              <>
                <div>
                  {spinner ? (
                    <button className="btn btn-eq-primary me-3 ">
                      <span className="loading">Downloading...</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-eq-primary me-3 "
                      onClick={handlePdfDownload}
                    >
                      Download
                    </button>
                  )}
                  <button className="btn btn-eq-primary" onClick={handlePrint}>
                    Print
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div ref={componentRef}>
            {investmentData?.question_response &&
              full?.offering?.questionnaire.map((section, index) => {
                return (
                  <div key={index}>
                    <h2 className="mb-0">
                      {section.title !== "Congrats! Almost finished!"
                        ? section.title
                        : ""}
                    </h2>
                    <h3 className="mb-0">{section.subtitle}</h3>
                    <div dangerouslySetInnerHTML={{ __html: section.text }} />
                    {section.questions.map((question, qIndex) => {
                      return (
                        <div key={qIndex}>
                          {investmentData?.question_response?.[question.id] && (
                            <label>
                              {investmentData?.question_response
                                .entity_individual === "entity" &&
                              question.id === "investor_name"
                                ? "Name of Investor"
                                : question.text}
                            </label>
                          )}

                          {question.type === "radio" && (
                            <>
                              {question.options?.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        defaultChecked={
                                          investmentData?.question_response?.[
                                            question.id
                                          ] === option.value
                                        }
                                        disabled
                                      />
                                      <label className="form-check-label ms-2">
                                        {option.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {question.type === "radio" &&
                            question.id !== "entity_individual" &&
                            investmentData?.question_response?.[question.id] ===
                              "yes" && (
                              <>
                                <div>
                                  <input
                                    type="text"
                                    name={question?.id}
                                    id={question?.id}
                                    className="form-control"
                                    placeholder={
                                      investmentData?.question_response[
                                        question.id.concat("_reason")
                                      ]
                                    }
                                    disabled
                                  />
                                </div>
                              </>
                            )}

                          {question.type === "checkbox" && (
                            <>
                              {question.options?.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        defaultChecked={
                                          investmentData?.question_response[
                                            option.id
                                          ] === true
                                        }
                                        disabled
                                      />
                                      <label className="form-check-label ms-2">
                                        {option.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {[
                            "address_line_1",
                            "address_city",
                            "address_state",
                            "address_postal_code",
                          ].includes(question.id) &&
                            investmentData?.question_response[question.id] && (
                              <p className="fw-bold">
                                {investmentData?.question_response[question.id]}
                              </p>
                            )}

                          {[
                            "text",
                            "ssn",
                            "ein",
                            "email",
                            "phone",
                            "zip",
                          ].includes(question.type) && (
                            <p className="fw-bold">
                              {investmentData?.question_response[question.id]}
                            </p>
                          )}
                        </div>
                      );
                    })}
                    {section.title === "Congrats! Almost finished!" ? (
                      <></>
                    ) : (
                      <hr />
                    )}
                  </div>
                );
              })}
          </div>

          {full?.offering?.status === "Active" &&
            hasEvent(investmentData?.event_history, "DILIGENCE", "SUBMITTED") &&
            !hasEvent(
              investmentData?.event_history,
              "DILIGENCE",
              "APPROVED"
            ) && (
              <Spinner show={spinner}>
                <div className="form-group">
                  <label className="mb-2">Description / Reason</label>
                  <textarea
                    className="form-control"
                    as="textarea"
                    rows="5"
                    name="description"
                    value={reviewDescription}
                    onChange={(e) => setReviewDescription(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-eq-primary"
                  onClick={() => handleInvestorApprove()}
                >
                  Approve
                </button>
                <button
                  className="btn btn-light ms-3"
                  onClick={() => handleInvestorReject()}
                >
                  Reject
                </button>
              </Spinner>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestmentOverview;
