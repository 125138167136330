/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    Badge,
    Button,
    ButtonGroup,
    IconButton,
    useToast,
  } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

import Spinner from "../../../components/spinner";
import Header from "../../../layouts/header";

import { Icon } from "@equidefi/ui/icon";
import TeamInviteModal from "../../../components/users/TeamInviteModal";
import TeammateModal from "../../../components/users/TeammateModal";
import { isAdmin, isIssuerOwner, removeIssuerUser } from "../../../helpers";
import { useIssuer, useIssuerUsers } from "../../../hooks/useIssuers";

const IssuerUsers = () => {
  const { id } = useParams();
  const { data: issuer } = useIssuer(id);
  const {
    data: users,
    isLoading,
    refetch,
  } = useIssuerUsers(id, {
    select: (data) => data?.users ?? [],
  });
  const { data: me } = useCurrentUser();

  const [inviteModal, showInviteModal] = useState(false);
  const [userModal, showUserModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const toast = useToast();

  const handleDelete = async (user) => {
    const status = await swal({
      title: `Remove User`,
      text: `Are you sure you want to remove ${[
        user.first_name,
        user.last_name,
      ].join(" ")}?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await removeIssuerUser(user.id, id);
      refetch();
    } catch (e) {
      console.error(e);
      toast({ status: "error", description: e?.response.data });
    }
  };

  return (
    <>
      <Header name="Users" title={issuer?.name}>
        {(isAdmin(me) || isIssuerOwner(me, id)) && (
          <Button onClick={() => showInviteModal(true)}>Invite</Button>
        )}
      </Header>

      <div className="container-fluid">
        <div className="card">
          <Spinner show={!inviteModal && isLoading}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Title / Company</th>
                    <th>Email</th>
                    <th>Team Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((u, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-baseline">
                          {u.first_name} {u.last_name}
                        </td>
                        <td className="align-baseline">
                          {[u.title, u.company].filter(Boolean).join(", ")}
                        </td>
                        <td className="align-baseline">{u.email}</td>
                        <td className="align-baseline">
                          <Badge
                            fontSize="0.7em"
                            colorScheme="gray"
                            variant="subtle"
                          >
                            {u.roles?.issuer?.[id]}
                          </Badge>
                        </td>
                        <td className="text-end align-baseline">
                          {(isAdmin(me) || isIssuerOwner(me, id)) && (
                            <ButtonGroup size="xs" colorScheme="gray">
                              <IconButton
                                onClick={() => {
                                  showUserModal(true);
                                  setUserData(u);
                                }}
                                icon={<Icon.Edit size="1em" />}
                                cursor="pointer"
                              />
                              <IconButton
                                onClick={() => handleDelete(u)}
                                icon={<Icon.Trash size="1em" />}
                                color="red"
                                cursor="pointer"
                              />
                            </ButtonGroup>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spinner>
        </div>
      </div>

      <TeamInviteModal
        issuerId={id}
        show={inviteModal}
        onHide={() => showInviteModal(false)}
      />
      <TeammateModal
        show={userModal}
        issuerId={id}
        user={userData}
        onHide={() => {
          showUserModal(false);
          setUserData(null);
          refetch();
        }}
      />
    </>
  );
};

export default IssuerUsers;
