/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button } from "@chakra-ui/react";

import Loader from "../../components/spinner";
import Header from "../../layouts/header";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import FloatingSearch from "../../components/floating-search";
import { isAdmin } from "../../helpers";
import useDebounce from "../../hooks/useDebounce";
import { useIssuers } from "../../hooks/useIssuers";

const Issuers = (props) => {
  const history = useHistory();

  const { data: me } = useCurrentUser();
  const [inputSearchValue, setSearchVal] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);

  const { data: issuers, isLoading: spinner } = useIssuers(searchVal);

  return (
    <>
      <Header name="Issuers">
        {isAdmin(me) && (
          <Button as={Link} to="/issuer/create">
            New Issuer
          </Button>
        )}
      </Header>

      <div className="container-fluid">
        <FloatingSearch
          placeholder="Search by name, description, type..."
          id="search-issuers"
          label="Search Issuers"
          onChange={(e) => setSearchVal(e.target.value)}
        />

        <Loader show={spinner}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-hover table-nowrap card-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {issuers?.map((issuer, index) => {
                    return (
                      <tr
                        key={issuer.id}
                        onClick={() =>
                          history.push(`/issuer/${issuer.id}/offerings`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td className="align-baseline">{issuer.name}</td>
                        <td className="text-capitalize align-baseline">
                          {issuer.cik ? (
                            <>
                              {issuer?.business_address?.city}
                              {issuer?.business_address?.city &&
                              issuer?.business_address?.stateOrCountry
                                ? ", "
                                : ""}
                              {issuer?.business_address?.stateOrCountry}
                            </>
                          ) : (
                            <>
                              {issuer?.address?.city}
                              {issuer?.address?.city && issuer?.address?.state
                                ? ", "
                                : ""}
                              {issuer?.address?.state}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Loader>
      </div>
    </>
  );
};

export default Issuers;
