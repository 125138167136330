import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { Input, Textarea } from "@equidefi/ui";

import { MESSAGE } from "../../../constants/forms";
import { useInvestmentRefundPayment } from "../../../hooks/investments";

const InitialRefundValues = {
  reference_number: "",
  notes: "",
};

const RefundSchema = Yup.object().shape({
  notes: Yup.string().required(MESSAGE.REQUIRED("notes")),
  reference_number: Yup.string().nullable(),
});

const ManualRefundModal = ({
  investmentId,
  paymentId,
  isVisible = false,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const toast = useToast();
  const refundPayment = useInvestmentRefundPayment(investmentId, {
    onSuccess: () => {
      onHide();
      onSubmit();
      toast({
        status: "success",
        description: "Payment has been refunded",
      });
    },
    onError: () => {
      toast({
        status: "error",
        description: "Something went wrong. Could not add manual refund.",
      });
    },
  });

  const handleManualRefund = async (data) => {
    return refundPayment.mutateAsync({
      payment_id: paymentId,
      reference_number: data.reference_number,
      notes: data.notes,
    });
  };

  return (
    <Modal isOpen={isVisible} onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Refund Payment</ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={InitialRefundValues}
          validationSchema={RefundSchema}
          onSubmit={handleManualRefund}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, errors, getFieldProps }) => (
            <form noValidate onSubmit={handleSubmit}>
              <ModalBody>
                <Textarea
                  {...getFieldProps("notes")}
                  isRequired
                  isInvalid={!!errors.notes}
                  error={errors.notes}
                  rows={3}
                  label="Notes"
                />

                <Input
                  {...getFieldProps("reference_number")}
                  label="FedWire/Check Number"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button isLoading={refundPayment.isLoading} type="submit">
                    Refund
                  </Button>
                  <Button
                    isDisabled={refundPayment.isLoading}
                    colorScheme="gray"
                    variant="ghost"
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ManualRefundModal;
