import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import CommonStockForm from "@equidefi/portals/components/investments/CommonStockForm";
import { Heading } from "@equidefi/ui";
import { useFormik } from "formik";
import * as Yup from "yup";

const editAmountSchema = Yup.object({
  quantity: Yup.number()
    .required(`Quantity is required`)
    .test({
      message: `Quantity must not be a decimal`,
      test: (quantity) => Number(quantity) === Math.round(quantity),
    }),
  amount: Yup.number().required(`Amount is required`),
});

export const EditAmountModal = ({ modal, investment, offering, onSubmit }) => {
  const { handleBlur, setValues, values, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        amount: investment?.amount,
        quantity: investment?.quantity,
      },
      enableReinitialize: true,
      validationSchema: editAmountSchema,
      onSubmit: onSubmit,
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
    });

  return (
    <Modal isOpen={modal.isOpen} onClose={() => modal.onClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={Heading}>Edit Amount</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CommonStockForm
            handleBlur={handleBlur}
            setValues={setValues}
            values={values}
            errors={errors}
            touched={touched}
            offering={offering}
          />
          <Alert mb="4">
            <AlertIcon />
            <AlertDescription>
              Please take note of where the investor is in the workflow and void agreements 
              and/or refund the investor based on their current workflow step.
              <br />
              <b>
                The investor must be placed back to the payment step manually.
              </b>
            </AlertDescription>
          </Alert>
        </ModalBody>
        <ModalFooter as={ButtonGroup}>
          <Button onClick={handleSubmit}>Save</Button>
          <Button
            variant="outline"
            onClick={() => {
              modal.onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
