import clsx from "clsx";

const FloatingSearch = ({
  classNames,
  id = "floatingInput",
  label,
  autoFocus = false,
  onChange = () => {},
  placeholder,
}) => {
  const classes = clsx("form-floating", "mb-3", classNames);

  return (
    <div className={classes}>
      <input
        type="text"
        className="form-control"
        autoFocus={autoFocus}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
      />
      <label htmlFor={id} className="text-black-50">
        {label}
      </label>
    </div>
  );
};

export default FloatingSearch;
