import { Formik } from "formik";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import UserToken from "@equidefi/portals/clients/UserToken";
import { useLogin } from "@equidefi/portals/hooks/useAuthentication";

import { Input, Password } from "@equidefi/ui/forms";
import { loginFormSchema } from "../../constants/forms";

const formData = {
  email: "",
  password: "",
};

const Login = (props) => {
  document.title = "Issuer Sign In | EquiDeFi";

  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginUser = useLogin();
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    const validToken = UserToken.valid();

    if (validToken) {
      history.push("/offering");
    }
  }, []);

  const handleLogin = async (form) => {
    showSpinner(true);
    const email = form.email.toLowerCase().trim();

    try {
      await loginUser.mutateAsync({
        email: email,
        password: form.password,
        type: "issuer",
      });

      dispatch({ type: "email", payload: email });
      props.history.push("/mfa", { email, password: form.password });
    } catch (error) {
      if (error.response?.status === 401) {
        toast({
          status: "error",
          description: "Login failed, try again or reset your password",
        });
      } else {
        toast({
          status: "error",
          description:
            error.response?.data?.errors?.join(". ") ?? "Something went wrong",
        });
      }
    } finally {
      showSpinner(false);
    }
  };

  return (
    <HStack as="main" wrap="nowrap" gap="0">
      <Flex
        as="section"
        h="100vh"
        w={{ base: "100%", md: "50%", lg: "33.3%" }}
        shrink="0"
        flexFlow="column nowrap"
        justify="center"
      >
        <Container px="16">
          <Center mb="8">
            <Image src="/img/logo.svg" w="200px" alt="logo" display="block" />
          </Center>

          <Heading textAlign="center" as="h1" size="lg" mb="2">
            Sign In
          </Heading>
          <Text textAlign="center" color="gray.400" mb="8">
            Issuer Portal
          </Text>

          <Formik
            initialValues={formData}
            validationSchema={loginFormSchema}
            onSubmit={handleLogin}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors, touched, getFieldProps }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Input
                  label="Email Address"
                  isRequired
                  isInvalid={errors.email && touched.email}
                  error={errors.email}
                  autoFocus
                  {...getFieldProps("email")}
                />

                <Password
                  label="Password"
                  isRequired
                  isInvalid={errors.password && touched.password}
                  error={errors.password}
                  {...getFieldProps("password")}
                />

                <Button isLoading={spinner} w="full" type="submit">
                  Continue
                </Button>

                <Center mt="4">
                  <Text
                    as={Link}
                    to="/forgot"
                    textAlign="center"
                    textDecor="underline"
                    mt="4"
                    fontSize="sm"
                    color="gray.400"
                  >
                    Forgot password?
                  </Text>
                </Center>
              </form>
            )}
          </Formik>
        </Container>
      </Flex>

      <Image objectFit="cover" w="full" h="100vh" src="img/login.jpg"></Image>
    </HStack>
  );
};

export default Login;
