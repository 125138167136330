import CerebriSansExtraBold from "@equidefi/portals/fonts/cerebrisans/cerebrisans-extrabold.ttf";
import CerebriSansMedium from "@equidefi/portals/fonts/cerebrisans/cerebrisans-medium.ttf";
import CerebriSansRegular from "@equidefi/portals/fonts/cerebrisans/cerebrisans-regular.ttf";
import { Font, StyleSheet } from "@react-pdf/renderer";

Font.register({ family: "Cerebri Sans", src: CerebriSansRegular });
Font.register({
  family: "Cerebri Sans",
  src: CerebriSansMedium,
  fontWeight: "medium",
});
Font.register({
  family: "Cerebri Sans",
  src: CerebriSansExtraBold,
  fontWeight: "heavy",
});

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
  },
  wrapper: {
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: "25px",
  },
  section: {
    margin: 0,
    padding: 0,
    // flexGrow: 1
  },
  svg: {
    maxWidth: "100%",
    height: "auto",
  },
  image: {
    width: 132,
    height: 42,
    margin: 0,
    padding: 0,
  },
  logo: {
    width: "100%",
    marginBottom: 50,
    padding: 0,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  invoice: {
    fontSize: 24,
    fontFamily: "Cerebri Sans",
    fontWeight: "heavy",
    margin: 0,
    padding: 0,
  },
  issuer: {
    // backgroundColor: 'blue',
    width: "40%",
  },
  due: {
    // backgroundColor: 'red',
    width: "40%",
    alignItems: "flex-end",
  },
  dueLabel: {
    fontFamily: "Cerebri Sans",
    fontSize: 10,
    fontWeight: "medium",
  },
  dueDate: {
    fontFamily: "Cerebri Sans",
    fontSize: 12,
  },
  name: {
    fontSize: 14,
    fontFamily: "Cerebri Sans",
    fontWeight: "medium",
  },
  address: {
    fontSize: 11,
    fontFamily: "Cerebri Sans",
  },
  phone: {
    fontSize: 11,
    fontFamily: "Cerebri Sans",
  },
  billable: {
    fontFamily: "Cerebri Sans",
    fontSize: 10,
    fontWeight: "medium",
  },
  row: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    // backgroundColor: 'green'
  },
  bill: {
    width: "100%",
    marginTop: 50,
    padding: 20,
    // backgroundColor: 'blue'
  },
  total: {
    width: "100%",
    height: 100,
    marginTop: "0",
    // backgroundColor: 'red'
  },
  table: {
    root: {
      // borderCollapse: "collapse",
      border: "1px solid #333333",
      padding: "7px",
    },
    header: {
      padding: "5px",
      fontFamily: "Cerebri Sans",
      fontWeight: "medium",
      fontSize: 11,
    },
    cell: {
      padding: "5px",
      fontFamily: "Cerebri Sans",
      fontSize: 9,
    },
    cellNoBorder: {
      border: 0,
      padding: "6px",
    },
    total: {
      border: 0,
      padding: "6px",
      fontFamily: "Cerebri Sans",
      fontSize: 10,
      fontWeight: "medium",
    },
  },
});
