import { Box, Flex, Heading, Spacer } from "@chakra-ui/react";
import React from "react";

import { Paper } from "@equidefi/ui";

export const InfoArea = ({
  title,
  headerRightElement,
  children,
  padding = 4,
  ...rest
}) => {
  return (
    <Paper {...rest}>
      <Flex
        direction="row"
        borderBottom="1px"
        borderColor="gray.100"
        align="center"
        p={4}
        w="full"
      >
        <Heading mb={0} as="h4" size="sm">
          {title}
        </Heading>
        <Spacer />
        {headerRightElement}
      </Flex>
      <Box px={padding}>{children}</Box>
    </Paper>
  );
};
