import { Button, Tooltip } from "@chakra-ui/react";
import { useMemo } from "react";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Icon } from "@equidefi/ui/icon";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";

const CountersignButton = ({
  isCountersignable = false,
  investmentId,
  ...props
}) => {
  const { data } = useInvestmentSignatures(investmentId);
  const { data: me } = useCurrentUser();

  const agreement = data?.investment_agreement;
  const summary = data?.summary ?? {};

  const { onClick, ...rest } = props;

  const isCountersigned = summary?.isCountersigned || false;
  const countersignButtonShow = !!agreement;

  const isCountersigner = useMemo(
    () => me?.email === data?.summary?.countersigner?.email,
    [data?.summary?.countersigner?.email, me?.email]
  );

  const countersignButtonEnabled =
    isCountersigner && isCountersignable && summary.readyToCountersign;

  const tooltipLabel = !isCountersigner
    ? "You are not the countersigner"
    : "Not ready to countersign";

  if (!countersignButtonShow) return null;

  if (isCountersigned) {
    return (
      <Button rightIcon={<Icon.Check size="1em" />} isDisabled {...rest}>
        Countersigned
      </Button>
    );
  }

  if (!countersignButtonEnabled) {
    return (
      <Tooltip hasArrow placement="top-start" label={tooltipLabel}>
        <Button isDisabled {...rest}>
          Countersign
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button onClick={onClick} isDisabled={!countersignButtonEnabled} {...rest}>
      Countersign
    </Button>
  );
};

export default CountersignButton;
