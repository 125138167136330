import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { IconControl, updateOffering } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";
import { calendarLimit } from "../../../helpers/utils";

const WizardCalendar = () => {
  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const schema = Yup.object().shape({
    termination_date: Yup.date()
      .typeError("")
      .when("initial_closing_date", (initial_closing_date) => {
        if (initial_closing_date) {
          return Yup.date()
            .min(
              initial_closing_date,
              "Termination Date must be after Initial Closing Date"
            )
            .typeError("");
        }
      }),
    extension_date: Yup.date()
      .typeError("")
      .when(
        ["initial_closing_date", "termination_date"],
        (initial_closing_date, termination_date) => {
          if (initial_closing_date) {
            if (initial_closing_date < termination_date) {
              return Yup.date()
                .min(
                  termination_date,
                  "Extension Date must be after Termination Date"
                )
                .typeError("");
            }
            return Yup.date()
              .min(
                initial_closing_date,
                "Extension Date must be after Initial Closing Date"
              )
              .typeError("");
          }
        }
      ),
  });

  const handleSubmit = async (data) => {
    showSpinner(true);
    data.investor_minimum_units =
      data.investor_minimum_amount / data.unit_price;
    await updateOffering(offering.id, data);
    refetch();
    showSpinner(false);
    history.push(`/offering/${offering.id}/wizard/account`);
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Please calendar your offering</h2>
      <hr />

      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values, errors, setFieldValue }) => {
            const initialDate = calendarLimit();
            const terminateDates = calendarLimit(values.initial_closing_date);
            const extensionDates = calendarLimit(values.termination_date);

            return (
              <form noValidate onSubmit={handleSubmit}>
                <IconControl
                  placement="right"
                  iconName="fe fe-info ms-2"
                  inputTitle="Initial Closing Date"
                  tooltipTitle="This is the date at which you anticipate your first closing to occur. You may change this date at a later time if you wish"
                />
                <FormControl
                  type="date"
                  name="initial_closing_date"
                  value={
                    values.initial_closing_date
                      ? new Date(values.initial_closing_date)
                      : null
                  }
                  error={errors.initial_closing_date}
                  minDate={initialDate.minDate}
                  onChange={(date) => {
                    setFieldValue("initial_closing_date", date.target.value);
                    if (!date.target.value) {
                      setFieldValue("termination_date", null);
                      setFieldValue("extension_date", null);
                    }
                  }}
                />

                <div
                  className={
                    !values.initial_closing_date ? "disable" : "enable"
                  }
                >
                  <IconControl
                    placement="right"
                    iconName="fe fe-info ms-2"
                    inputTitle="Termination Date"
                    tooltipTitle="This is the date at which your offering is supposed to terminate. Your offering will automatically close at this date if you did not close it previously and your investors will receive a notification that the offering is closed. This date cannot precede the Initial Closing Date"
                  />
                  <FormControl
                    type="date"
                    name="termination_date"
                    value={
                      values.termination_date
                        ? new Date(values.termination_date)
                        : null
                    }
                    error={errors.termination_date}
                    minDate={terminateDates.minDate}
                    maxDate={terminateDates.maxDate}
                    disabled={!values.initial_closing_date}
                    onChange={(date) => {
                      setFieldValue("termination_date", date.target.value);
                      if (!date.target.value) {
                        setFieldValue("extension_date", null);
                      }
                    }}
                  />
                </div>
                <div
                  className={!values.termination_date ? "disable" : "enable"}
                >
                  <IconControl
                    placement="right"
                    iconName="fe fe-info ms-2"
                    inputTitle="Extension Date"
                    tooltipTitle="This is the date you want to extend your offering beyond the termination date. Your offering will automatically close at this date if you did not close it previously, and your investors will receive a notification that the offering is closed"
                  />
                  <FormControl
                    type="date"
                    name="extension_date"
                    value={
                      values.extension_date
                        ? new Date(values.extension_date)
                        : null
                    }
                    error={errors.extension_date}
                    minDate={extensionDates.minDate}
                    maxDate={extensionDates.maxDate}
                    disabled={!values.termination_date}
                    onChange={handleChange}
                  />
                </div>
                <hr />
                <button className="btn btn-eq-primary">
                  Save &amp; Continue
                </button>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardCalendar;
