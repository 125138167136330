import React from "react";

import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import InvestmentAgreementCard from "./InvestmentAgreementCard";

const InvestmentSignatures = ({
  investment,
  investmentId,
  isCountersignable = false,
}) => {
  const { data } = useInvestmentSignatures(investmentId);

  const investmentAgreement = data?.investment_agreement;
  const signatures = data?.signatures ?? [];

  return (
    <InvestmentAgreementCard
      key={investmentAgreement?.id}
      isCountersignable={isCountersignable}
      summary={data?.summary}
      investmentId={investmentId}
      agreement={investmentAgreement}
      signatures={signatures}
    />
  );
};

export default InvestmentSignatures;
