import { Box } from "@chakra-ui/react";
import FileSaver from "file-saver";
import React, { useState } from "react";

import { Paper } from "@equidefi/ui";

import { ApiInstance } from "../../../clients/api";
import { useInvestmentAgreementRecipients } from "../../../hooks/investments";
import Spinner from "../../spinner";
import AgreementRow from "./AgreementRow";

const InvestmentAgreements = ({
  investment,
  canView,
  canCountersign = false,
}) => {
  const {
    data: agreements,
    isSuccess,
    isError,
  } = useInvestmentAgreementRecipients(investment?.id);

  const [loading, setLoading] = useState(false);

  const downloadAgreement = async (agreement) => {
    try {
      const response = await ApiInstance.get(
        `investment/${investment.id}/agreement/${agreement.envelope_id}`
      );
      let toBytes = new Uint8Array(response.data.data),
        toBinary = ``;
      toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));
      const file = window.btoa(toBinary);
      FileSaver.saveAs(
        `data:application/pdf;base64,${file}`,
        agreement.name.replace(/[^a-zA-Z ]/g, "")
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleDownloadAgreement = async (agreement) => {
    setLoading(true);
    await downloadAgreement(agreement);
    setLoading(false);
  };

  let Body = <Spinner show />;

  if (loading) {
    Body = <Spinner show />;
  } else if (isError) {
    Body = (
      <ul className="list-group list-group-flush">
        <li className="list-group-item text-muted">
          Sorry! Something went wrong loading agreements for this investment.
          Our team has been notified.
        </li>
      </ul>
    );
  } else if (isSuccess && agreements?.length === 0) {
    Body = (
      <ul className="list-group list-group-flush">
        <li className="list-group-item text-muted">No agreements</li>
      </ul>
    );
  } else if (agreements?.length > 0) {
    Body = (
      <ul className="list-group list-group-flush">
        {agreements?.map((agreement, i) => (
          <AgreementRow
            key={`${i}-${agreement.id}`}
            agreement={agreement}
            canView={canView}
            canCountersign={canCountersign}
            investmentId={investment.id}
            onDownload={handleDownloadAgreement}
          />
        ))}
      </ul>
    );
  }

  return (
    <Paper mb={8}>
      <div className="card-header">
        <h4 className="card-header-title">Agreements</h4>
      </div>
      <Box p={0} px={6}>
        {Body}
      </Box>
    </Paper>
  );
};

export default InvestmentAgreements;
