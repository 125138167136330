import React, { useMemo, useState } from "react";

import {
  Badge,
  Button,
  Flex,
  Spacer,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { ApiInstance } from "../../../clients/api";
import {
  allInvestorsSigned,
  allRecipientsSigned,
  hasIssuerCountersigned,
  signerMap,
} from "../../../helpers/investments";

const AgreementRow = ({
  investmentId,
  agreement,
  onDownload = () => {},
  canCountersign = false,
  canView = false,
}) => {
  const toast = useToast();
  const { data: me } = useCurrentUser();

  /**
   * @type {import("docusign-esign").Recipients}
   */
  const recipients = useMemo(() => agreement?.recipients ?? [], [agreement]);
  const [loading, setLoading] = useState(false);
  const isSignatory = signerMap(recipients).get("issuer").email === me.email;

  const handleCountersign = async (agreement) => {
    setLoading(true);

    try {
      const response = await ApiInstance.get(
        `investment/${investmentId}/agreement/${agreement.envelope_id}/url/issuer`
      );

      window.open(response.data.url, "_blank");
    } catch (e) {
      toast({
        status: "error",
        description: "Sorry, something went wrong loading the agreement",
      });
    } finally {
      setLoading(false);
    }
  };

  const readyToCountersign = isSignatory && canCountersign;

  return (
    <li className="list-group-item">
      <Flex>
        <div>
          <Text as="p" mb={1}>
            {agreement.agreement.name}
          </Text>
          <Flex gap={1}>
            {Array.from(signerMap(recipients).entries()).map(([, signer]) => {
              const statusBg = signer.signed ? "green" : "yellow";
              const variant = signer.signed ? "solid" : "subtle";

              return (
                <Badge
                  key={signer.id}
                  size="sm"
                  variant={variant}
                  colorScheme={statusBg}
                  fontWeight={300}
                  textTransform="capitalize"
                >
                  {signer.role} {signer.status ?? "pending"}
                </Badge>
              );
            })}
          </Flex>
        </div>

        <Spacer />

        {!hasIssuerCountersigned(agreement.recipients) &&
          allInvestorsSigned(agreement.recipients) &&
          (canCountersign || canView) && (
            <Tooltip
              placement="auto-start"
              hasArrow
              isDisabled={isSignatory}
              label={
                !canCountersign
                  ? `Cannot countersign this document yet.`
                  : `${
                      signerMap(agreement.recipients).get("issuer").name
                    } is the authorized signatory for this agreement.`
              }
            >
              <Button
                leftIcon={
                  !readyToCountersign ? <Icon.AlertCircle size="1em" /> : null
                }
                isLoading={loading}
                isDisabled={!readyToCountersign}
                size="sm"
                onClick={() => handleCountersign(agreement.agreement)}
              >
                Countersign
              </Button>
            </Tooltip>
          )}

        {allRecipientsSigned(agreement.recipients) && canView && (
          <Button
            size="sm"
            colorScheme="gray"
            onClick={() => onDownload(agreement.agreement)}
          >
            Download
          </Button>
        )}
      </Flex>
    </li>
  );
};

export default AgreementRow;
