import { ApiClient } from "@equidefi/portals/clients/ApiClient";
import { ApiInstance } from "./api";

class InvitationClient extends ApiClient {
  async issuerInvite(issuerId, data) {
    const response = await this.post(`/v2/users/invitation/issuer`, {
      issuer_id: issuerId,
      ...data,
    });
    return response.data;
  }

  async offeringInvite(offeringId, data) {
    const response = await ApiInstance.post(`/v2/users/invitation/offering`, {
      offering_id: offeringId,
      ...data,
    });
    return response.data;
  }

  async getInvitations(type, id) {
    const response = await ApiInstance.get(
      `/v2/users/invitations/${type.toLowerCase()}/${id}`
    );
    return response.data;
  }

  async deleteInvitation(invitationId) {
    const response = await ApiInstance.delete(
      `/v2/users/invitation/${invitationId}`
    );
    return response.data;
  }

  async verify(invitationId) {
    const response = await ApiInstance.get(
      `/v2/users/invitation/verify/${invitationId}`
    );
    return response.data;
  }
}
export default InvitationClient;
