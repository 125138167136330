import {
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import { useEffect, useState } from "react";

const SearchBar = ({
  defaultSearchPhrase = "",
  onClear,
  onSearch,
  placeholder,
  ...rest
}) => {
  const [searchPhrase, setSearchPhrase] = useState(defaultSearchPhrase);

  useEffect(() => {
    if (!searchPhrase && defaultSearchPhrase) {
      onClear();
    }
  }, [searchPhrase, onClear, defaultSearchPhrase]);

  const handleClear = () => {
    setSearchPhrase("");
    onClear();
  };

  return (
    <InputGroup size="sm" {...rest}>
      <Input
        type="text"
        placeholder={placeholder}
        borderRadius="md"
        onChange={(e) => setSearchPhrase(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") onSearch(searchPhrase);
        }}
        value={searchPhrase}
      />
      <InputRightElement
        mr={10}
        children={
          searchPhrase ? (
            <Icon.XCircle
              cursor="pointer"
              color="gray"
              size="1em"
              onClick={handleClear}
            />
          ) : null
        }
      />
      <InputRightAddon p={0}>
        <IconButton
          aria-label="Search"
          size="sm"
          icon={<Icon.Search size="1em" />}
          borderLeftRadius={0}
          onClick={() => onSearch(searchPhrase)}
        />
      </InputRightAddon>
    </InputGroup>
  );
};

export default SearchBar;
