import { useMutation } from "@tanstack/react-query";
import { useApi } from "./useApi";
import FileUploadClient from "../clients/FileUploadClient";

export const usePresignedPublicUrl = (options = {}) => {
  const client = useApi(FileUploadClient);

  return useMutation(
    async (data: { filename: string; extension: string }) =>
      client.getPresignedPublicUrl(data.filename, data.extension),
    options
  );
};
