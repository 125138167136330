import {
  useQuery
} from "@tanstack/react-query";

import InquiryClient from "../clients/InquiryClient";
import { useApi } from "./useApi";

export const useInvestmentInquiries = (investmentId, options = {}) => {
  const inquiryApi = useApi(InquiryClient);

  return useQuery(
    ["investment", investmentId, "inquiries"],
    () => inquiryApi.getInvestmentInquiries(investmentId),
    {
      enabled: !!investmentId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );
};
