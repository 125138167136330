import { ApiInstance } from "./api";

class InvoiceClient {
  static async get(id) {
    const response = await ApiInstance.get(`/v2/invoices/${id}`);
    return response.data;
  }
}

export default InvoiceClient;
