const COMMON_STOCK = "COMMON_STOCK";
const PREFERRED_STOCK = "PREFERRED_STOCK";
const CONVERTIBLE_NOTE = "CONVERTIBLE_NOTE";
const UNIT = "UNIT";
const SAFE = "SAFE";
const IN_LIEU_OF_CASH = "IN_LIEU_OF_CASH";

export const STOCK_TYPES = {
  COMMON_STOCK: COMMON_STOCK,
  PREFERRED_STOCK: PREFERRED_STOCK,
  CONVERTIBLE_NOTE: CONVERTIBLE_NOTE,
  UNIT: UNIT,
  SAFE: SAFE,
  IN_LIEU_OF_CASH: IN_LIEU_OF_CASH,
};

export const StockTypeMap = {
  common_stock: COMMON_STOCK,
  preferred_stock: PREFERRED_STOCK,
  convertible_note: CONVERTIBLE_NOTE,
  unit: UNIT,
  safe: SAFE,
  in_lieu_of_cash: IN_LIEU_OF_CASH,
};
