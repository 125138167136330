import {
  ButtonGroup,
  ButtonGroupProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Breakpoints } from "./Global";

enum OrientationTypes {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

interface ButtonGroupResponsiveProps
  extends Omit<ButtonGroupProps, "orientation"> {
  orientation?:
    | OrientationTypes
    | {
        [key in Breakpoints]: OrientationTypes;
      };
}

export const ButtonGroupResponsive = (props: ButtonGroupResponsiveProps) => {
  const { orientation = "horizontal", children, ...rest } = props;

  const orientationFixed =
    typeof orientation === "string"
      ? orientation
      : useBreakpointValue(orientation);

  return (
    <ButtonGroup orientation={orientationFixed} {...rest}>
      {children}
    </ButtonGroup>
  );
};
