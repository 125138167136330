export const calculateStockTypeName = (data) => {
  let stockName;

  switch (data.stock_type) {
    case "preferred_stock":
      if (
        data.preferred_sub_type === "series" &&
        data.preferred_series === "percentage"
      ) {
        stockName = `${data.preferred_percentage}% Class ${data.preferred_class} Preferred Stock`;
        break;
      } else if (
        data.preferred_sub_type === "series" &&
        data.preferred_series === "none"
      ) {
        stockName = `Class ${data.preferred_class} Preferred Stock`;
        break;
      } else if (data.preferred_sub_type === "series") {
        stockName = `Class ${data.preferred_class} Preferred Stock`;
        break;
      } else {
        stockName = `Preferred Stock`;
        break;
      }

    case "common_stock":
      stockName =
        data.sub_type === "class"
          ? `Class ${data.common_stock_class} Common Stock`
          : data.stock_type;
      break;

    case "safe":
      if (data.safe_sub_type === "post_money_valuation_cap") {
        stockName = `Safe with $${data.safe_pm_valuation_cap} Post-Money Valuation Cap`;
      } else if (data.safe_sub_type === "discount_rate") {
        stockName = `Safe with ${data.safe_discount}% discount rate`;
      } else {
        stockName = `Safe with $${data.safe_amount} valuation cap ${data.safe_percentage}% discount`;
      }
      break;

    case "in_lieu_of_cash":
      if (data.in_lieu_of_cash_sub_type === "in_lieu_of_cash_common_stock") {
        if (data.in_lieu_class_subtype === "class") {
          stockName = `In lieu of cash with common stock with class ${data.common_stock_in_lieu_class}`;
        } else {
          stockName = `In lieu of cash with common stock`;
        }
      } else if (
        data.in_lieu_of_cash_sub_type === "in_lieu_of_cash_convertible_note"
      ) {
        stockName = `In lieu of cash with Convertible Notes`;
      }
      break;

    case "unit":
      // Base stock type name is "Unit"
      stockName = "Unit";

      // If sub-type 1 is selected, include in the stock name
      if (data.unit_sub_type1 === "unit_common_stock") {
        stockName = `${stockName} with common stock`;
      } else if (data.unit_sub_type1 === "unit_preferred_stock") {
        stockName = `${stockName} with preferred stock`;
      } else if (data.unit_sub_type1 === "unit_convertable_notes") {
        stockName = `${stockName} with convertible notes`;
      }

      /**
       * If sub-type 2 is selected, a sub-type 1 is already selected and we
       * can append the sub-type 2 stock name
       */
      if (data.unit_sub_type2 === "with_warrants") {
        stockName = `${stockName} & warrants`;
      } else if (data.unit_sub_type2 === "unit_preferred_stock") {
        stockName = `${stockName} & preferred stock`;
      }

      break;

    default:
      stockName = data.stock_type;
      break;
  }

  return stockName;
};

export const isUnitOffering = (_offering) => {
  return _offering.type === "UNIT";
};

export const isFloatValueAllowed = (values, maxValue) => {
  const { floatValue } = values;
  return !floatValue || floatValue < maxValue;
};
