type EventHistoryItem = {
  workflow_action: string;
  workflow_event: string;
  [key: string]: unknown;
};

const eventMatch =
  (action: string, event: string) => (item: EventHistoryItem) =>
    item.workflow_action === action && item.workflow_event === event;

export const getEvent = (
  history: EventHistoryItem[],
  event: string,
  action: string
) => {
  return (history ?? []).find(eventMatch(action, event));
};

export const hasEvent = (
  history: EventHistoryItem[],
  event: string,
  action: string
) => {
  const found = (history ?? []).findIndex(eventMatch(action, event));

  return found !== -1;
};
