import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import * as Yup from "yup";

import { Input, Select, Textarea } from "@equidefi/ui";

import NumberFormat from "react-number-format";
import { MESSAGE } from "../../../constants/forms";
import { useInvestmentAddManualPayment } from "../../../hooks/investments";

const options = [
  { label: "Wire", value: "wire" },
  { label: "Check", value: "check" },
];

const PaymentSchema = Yup.object().shape({
  amount: Yup.number()
    .required(MESSAGE.REQUIRED("amount"))
    .moreThan(0, "Must not be $0"),
  payment_type: Yup.string().required(MESSAGE.REQUIRED("payment type")),
  notes: Yup.string().required(MESSAGE.REQUIRED("notes")),
  reference_number: Yup.string().nullable(),
});

const ManualPaymentModal = ({
  investmentId,
  isVisible = false,
  initialAmount = 0,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const InitialPaymentValues = useMemo(
    () => ({
      amount: initialAmount ?? 0,
      payment_type: "",
      reference_number: "",
      notes: "",
    }),
    [initialAmount]
  );
  const toast = useToast();
  const addPayment = useInvestmentAddManualPayment(investmentId, {
    onSuccess: () => {
      onHide();
      onSubmit();
      toast({
        status: "success",
        description: "Manual payment has been added",
      });
    },
    onError: (e) => {
      toast({
        status: "error",
        description:
          e.response?.data?.errors?.join(", ") ??
          "Something went wrong. Could not add manual payment.",
      });
    },
  });

  const handleManualPayment = async (data) => addPayment.mutateAsync({ data });

  const form = useFormik({
    initialValues: InitialPaymentValues,
    validationSchema: PaymentSchema,
    onSubmit: handleManualPayment,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const value = options?.find((option) => option.value === form?.signatory_id);
  const handlePaymentTypeChange = (data) => {
    if (!data) return form.setFieldValue("payment_type", null);
    return form.setFieldValue("payment_type", data.value);
  };

  return (
    <Modal isOpen={isVisible} onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <form noValidate onSubmit={form.handleSubmit}>
          <ModalHeader>Add Manual Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              autoFocus={false}
              label={"Payment Type"}
              options={options}
              {...form.getFieldProps("payment_type")}
              value={value}
              onChange={handlePaymentTypeChange}
              isRequired
              error={form.errors.payment_type}
              isInvalid={!!form.errors.payment_type}
              components={{
                IndicatorSeparator: null,
              }}
              mb={4}
            />

            <Input
              as={NumberFormat}
              allowNegative={false}
              allowLeadingZeros
              thousandSeparator
              prefix="$"
              isRequired
              error={form.errors.amount}
              isInvalid={!!form.errors.amount}
              label="Amount"
              value={form.getFieldProps("amount").value}
              onBlur={form.getFieldProps("amount").onBlur}
              placeholder="e.g. $5,000"
              onValueChange={({ value }) =>
                form.getFieldProps("amount").onChange({
                  target: { name: "amount", value: Number(value) },
                })
              }
            />

            <Textarea
              {...form.getFieldProps("notes")}
              isRequired
              error={form.errors.notes}
              isInvalid={!!form.errors.notes}
              rows={3}
              label="Notes"
            />

            <Input
              {...form.getFieldProps("reference_number")}
              label="FedWire/Check Number"
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup size="sm">
              <Button isLoading={addPayment.isLoading} type="submit">
                Continue
              </Button>
              <Button
                isDisabled={addPayment.isLoading}
                variant="ghost"
                colorScheme="gray"
                onClick={onHide}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ManualPaymentModal;
