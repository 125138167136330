import { useState } from "react";
import Dropzone from "react-dropzone";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  createOfferingAgreement,
  updateOfferingAgreement,
  // upload,
} from "../../helpers";
import { usePresignedPublicUrl } from "@equidefi/portals/hooks/useUpload";
import { MESSAGE } from "../../constants/forms";
import {
  Box,
  Heading,
  Modal,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
  ButtonGroup,
  FormErrorMessage,
} from "@chakra-ui/react";

const acceptedFileTypes = {
  "application/msword": [".doc", ".docx"],
  "application/pdf": [".pdf"],
};

const agreementSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.REQUIRED("name")),
  template_id: Yup.string().required(MESSAGE.REQUIRED("template ID")),
  files: Yup.array().required(MESSAGE.REQUIRED("files")),
});

export const AgreementUploadModal = ({
  agreement,
  offering,
  isOpen,
  onClose,
  onComplete,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const presignedUrl = usePresignedPublicUrl();
  const toast = useToast();

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      if (agreement.id) {
        await updateAgreement(formData);
      } else {
        await createAgreement(formData);
      }
      await onComplete();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAgreement = async (formData) => {
    const fileURL =
      typeof formData.files[0] === "string"
        ? agreement.agreement_url
        : await uploadFile(formData.files[0]);

    await updateOfferingAgreement(offering.id, agreement.id, {
      name: formData.name,
      template_id: formData.template_id,
      agreement_url: fileURL,
    });
    toast({
      description: `${formData.name} has been updated`,
      status: "success",
      isClosable: true,
    });
  };

  const createAgreement = async (formData) => {
    for (const file of formData.files) {
      const fileURL = await uploadFile(file);
      await createOfferingAgreement(offering.id, {
        name: formData.name,
        template_id: formData.template_id,
        agreement_url: fileURL,
      });
    }
  };

  const uploadFile = async (file) => {
    let data = new FormData();
    data.append("file", file);

    // const response = await upload(data)
    // return response.url
  };

  const getFilesFromURL = (agreementURL) => {
    if (!agreementURL) return [];
    const url = new URL(agreementURL);
    return [decodeURIComponent(url.pathname)];
  };

  const initialValues = {
    name: agreement?.name,
    template_id: agreement?.template_id,
    files: getFilesFromURL(agreement?.agreement_url),
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent py={1.5} px={8}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={agreementSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ setFieldValue, handleSubmit, values, errors, getFieldProps }) => (
            <>
              <Heading size="md" mt={5}>
                {agreement.id ? "Edit" : "Upload"} Agreement
              </Heading>
              <FormControl isRequired isInvalid={errors.name}>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  {...getFieldProps("name")}
                  className="form-control"
                  placeholder="eg: Recon Document"
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={errors.template_id}>
                <FormLabel htmlFor="name">Template Id</FormLabel>
                <Input
                  {...getFieldProps("template_id")}
                  className="form-control"
                />
                <FormErrorMessage>{errors.template_id}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={errors.files}>
                <Dropzone
                  maxFiles={agreement.id ? 1 : null}
                  multiple={!agreement.id}
                  accept={acceptedFileTypes}
                  onDrop={(acceptedFiles) => {
                    setFieldValue("files", acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()} className="dropzone">
                      <Input {...getInputProps()} />
                      {values.files.length ? (
                        values.files.map((file) => (
                          <Box>{file?.path || file} </Box>
                        ))
                      ) : (
                        <Text>
                          Drag 'n' drop the respective files here, or click to
                          select files
                        </Text>
                      )}
                    </Box>
                  )}
                </Dropzone>
                <FormErrorMessage>{errors.files}</FormErrorMessage>
              </FormControl>
              <ModalFooter>
                <ButtonGroup spacing="3">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                  >
                    {agreement.id ? "Save" : "Upload"}
                  </Button>
                  <Button variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
