import * as React from "react";

import clsx from "clsx";

type HeaderProps = {
  children: React.ReactNode;
  subtitle?: string;
  onClick?: Function;
  buttonLabel?: string;
  styles?: {
    header: string;
    subtitle: string;
    title: string;
    left: string;
    right: string;
    button: string;
  };
};

export const Header = ({
  children,
  subtitle,
  styles,
  onClick,
  buttonLabel,
}: HeaderProps) => {
  return (
    <div className={clsx(styles?.header ?? "header")}>
      <div className={clsx(styles?.left ?? "left")}>
        <h5 className={clsx(styles?.subtitle ?? "subtitle")}>{subtitle}</h5>
        <h1 className={clsx(styles?.title ?? "title")}>{children}</h1>
      </div>
      {onClick && buttonLabel ? (
        <div className={clsx(styles?.right ?? "right")}>
          <div>
            <button
              onClick={() => onClick()}
              className={clsx(styles?.button ?? "button")}
            >
              {buttonLabel}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
