import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

import { isAdmin } from "../../../helpers";

const STATUS_MAP = {
  Draft: {
    key: "draft",
  },
  "In Review": {
    key: "in_review",
  },
  Active: {
    key: "active",
    admin: true,
  },
  Closed: {
    key: "closed",
  },
  Terminated: {
    key: "terminated",
  },
};

const OfferingStatusMenu = ({ user, currentStatus, onSelect = () => {} }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        colorScheme="gray"
        rightIcon={<Icon.ChevronDown size="1em" />}
      >
        {currentStatus}
      </MenuButton>
      <MenuList>
        {Object.entries(STATUS_MAP)
          .filter(([status]) => status !== currentStatus)
          .map(([status, { key, admin }]) => {
            const canUpdate = admin ? isAdmin(user) : true;
            const icon = !canUpdate ? <Icon.Slash size="1em" /> : null;

            return (
              <MenuItem
                key={key}
                command={icon} // command is on the right, so better alignment
                isDisabled={!canUpdate}
                onClick={() => onSelect(status, key)}
              >
                {status}
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
};

export default OfferingStatusMenu;
