/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonGroup, Stack, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { Input, Paper } from "@equidefi/ui";

import Header from "../../layouts/header";

import {
  useBroker,
  useBrokerCreate,
  useBrokerUpdate,
} from "../../hooks/useBrokers";

import { brokerFormSchema } from "../../constants/forms";

const DEFAULT_DATA = {
  first_name: null,
  last_name: null,
  company: null,
  crd_number: null,
};

const BrokerEditor = (props) => {
  const toast = useToast();
  const history = useHistory();
  const { id } = useParams();
  const [spinner, showSpinner] = useState(!!id);

  const { data: broker } = useBroker(id, {
    onSettled: () => {
      showSpinner(false);
    },
    refetchOnMount: true,
  });

  const brokerCreate = useBrokerCreate({
    onSuccess: (data) => {
      toast({
        status: "success",
        description: `Broker ${data.first_name} ${data.last_name} has been created`,
      });
      history.push(`/brokers`);
    },
    onSettled: () => {
      showSpinner(false);
    },
    onError: (error) => {
      console.error(error);
      error?.response.data.errors.forEach((e) => {
        toast({ description: e, status: "error" });
      });
    },
  });

  const brokerUpdate = useBrokerUpdate(id, {
    onSuccess: (data) => {
      toast({
        status: "success",
        description: `Broker ${data.first_name} ${data.last_name} has been updated`,
      });
      history.push(`/brokers`);
    },
    onSettled: () => {
      showSpinner(false);
    },
    onError: (error) => {
      console.error(error);
      error?.response.data.errors.forEach((e) => {
        toast({ description: e, status: "error" });
      });
    },
  });

  const handleOnSubmit = async (data) => {
    showSpinner(true);

    if (id) {
      await brokerUpdate.mutateAsync({ data });
    } else {
      await brokerCreate.mutateAsync({ data });
    }

    showSpinner(false);
  };

  return (
    <>
      <Header title="EquiDeFi" name={id ? "Edit Broker" : "Create Broker"} />
      <div className="container-fluid mb-5">
        <Paper p="7">
          <Formik
            enableReinitialize={true}
            initialValues={broker ?? DEFAULT_DATA}
            validationSchema={brokerFormSchema}
            onSubmit={handleOnSubmit}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({ handleChange, errors, touched, getFieldProps }) => {
              return (
                <Form noValidate>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    w="full"
                    gap={{ md: 4 }}
                  >
                    <Input
                      label="First Name"
                      placeholder="e.g. John"
                      error={errors.first_name}
                      isInvalid={errors.first_name && touched.first_name}
                      isRequired
                      {...getFieldProps("first_name")}
                    />
                    <Input
                      label="Last Name"
                      placeholder="e.g. Smith"
                      error={errors.last_name}
                      isInvalid={errors.last_name && touched.last_name}
                      isRequired
                      {...getFieldProps("last_name")}
                    />
                  </Stack>

                  <Input
                    label="Company Name"
                    placeholder="e.g. Acme Corporation"
                    error={errors.company}
                    isInvalid={errors.company && touched.company}
                    {...getFieldProps("company")}
                  />

                  <Stack
                    direction={{ base: "column", md: "row" }}
                    w="full"
                    gap={{ md: 4 }}
                  >
                    <Input
                      label="Tracking Code"
                      placeholder="e.g. skql91b"
                      error={errors.code}
                      isInvalid={errors.code && touched.code}
                      minLength={5}
                      maxLength={7}
                      isRequired
                      isDisabled={!!id}
                      {...getFieldProps("code")}
                    />
                    <Input
                      label="CRD Number"
                      placeholder="e.g. 5432541"
                      error={errors.crd_number}
                      isInvalid={errors.crd_number && touched.crd_number}
                      maxLength={7}
                      isRequired
                      isDisabled={!!id}
                      {...getFieldProps("crd_number")}
                    />
                  </Stack>

                  <hr />

                  <ButtonGroup size="sm" spacing="2">
                    <Button isLoading={spinner} type="submit">
                      {id ? "Update" : "Create"}
                    </Button>
                    <Button
                      as={Link}
                      to="/brokers"
                      variant="ghost"
                      isDisabled={spinner}
                      colorScheme="gray"
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </div>
    </>
  );
};

export default BrokerEditor;
