/* eslint-disable react-hooks/exhaustive-deps */
import * as Sentry from "@sentry/react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { groupBy } from "lodash";
import Spinner from "../../components/spinner";
import { isAdmin, updateSettingById } from "../../helpers";
import { useOffering, useOfferingSettings } from "../../hooks/useOfferings";
import Header from "../../layouts/header";
import SettingsGroup from "./components/SettingsGroup";

const Settings = (props) => {
  const { id } = useParams();
  const { data: offering } = useOffering(id);
  const {
    data: settings,
    isLoading,
    refetch: refetchSettings,
  } = useOfferingSettings(id);

  const toast = useToast();

  const { data: me } = useCurrentUser();

  const updateSetting = async (settingId, name, value) => {
    try {
      await updateSettingById(offering.id, settingId, value);
      await refetchSettings();

      toast({
        status: "success",
        description: `${name} successfully saved`,
      });
    } catch (e) {
      console.error(`Setting ${name} could not be saved`, e);
      Sentry.captureException(e);

      toast({
        status: "error",
        description: `${name} could not be saved`,
      });
    }
  };

  const settingGroups = useMemo(
    () => groupBy(settings, "category"),
    [settings]
  );

  const isEditable = useMemo(
    () =>
      isAdmin(me, offering?.id) &&
      ["In Review", "Draft"].includes(offering?.status),
    [me, offering?.id, offering?.status]
  );

  return (
    <>
      <Header id={id} name="Settings" title={offering?.name} />

      <Spinner show={isLoading}>
        <div className="container-fluid">
          <SettingsGroup
            title="Instructions"
            onEdit={updateSetting}
            isEditable={isEditable}
            settings={settingGroups.instructions}
          />

          <SettingsGroup
            title="Bank"
            subtitle="Offering Bank Settings"
            onEdit={updateSetting}
            isEditable={isEditable}
            settings={settingGroups.bank}
          />

          <SettingsGroup
            title="Transfer Agent"
            subtitle="Authorized Agent Details"
            adjustName={(name) => name.replace(/^Transfer Agent:\s/i, "")}
            onEdit={updateSetting}
            isEditable={isEditable}
            settings={settingGroups.transfer_agent}
          />

          <SettingsGroup
            title="Escrow Agent"
            // subtitle="Agent Details"
            adjustName={(name) => name.replace(/^Escrow Agent:\s/i, "")}
            onEdit={updateSetting}
            isEditable={isEditable}
            settings={settingGroups.escrow_agent}
          />
        </div>
      </Spinner>
    </>
  );
};

export default Settings;
