import { Badge } from "@chakra-ui/react";
import React from "react";

const OfferingStatus = {
  Draft: "gray",
  "In Review": "blue",
  Active: "green",
  Closed: "gray",
  Terminated: "gray",
};

const OfferingStatusBadge = ({ as: Tag = Badge, status, ...rest }) => {
  const color = OfferingStatus[status] ?? "gray";

  return (
    <Tag colorScheme={color} {...rest}>
      {status}
    </Tag>
  );
};

export default OfferingStatusBadge;
