import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Input, Textarea } from "@equidefi/ui";

import { Button } from "@chakra-ui/react";
import Spinner from "../../../components/spinner";
import { profileFormSchema } from "../../../constants/forms";
import {
  useOfferingFull,
  useOfferingUpdate,
} from "../../../hooks/useOfferings";

const WizardProfile = (props) => {
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data, refetch } = useOfferingFull(id);
  const { offering } = data ?? {};

  const { mutateAsync: updateOffering } = useOfferingUpdate(id);

  const handleOnSubmit = async (data) => {
    showSpinner(true);
    await updateOffering(data);
    refetch();
    showSpinner(false);
    history.push(`/offering/${offering.id}/wizard/subscription`);
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Tell us more about your company</h2>
      <hr />

      <Spinner show={spinner}>
        <Formik
          enableReinitialize
          initialValues={{
            name: offering.name,
            description: offering.description,
            stock_ticker: offering.stock_ticker,
          }}
          validationSchema={profileFormSchema}
          onSubmit={handleOnSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, getFieldProps, errors, touched }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Input
                label="Offering Name"
                error={errors.name}
                isInvalid={errors.name && touched.name}
                {...getFieldProps("name")}
              />

              <Textarea
                label="Company Business Description"
                isRequired
                error={errors.description}
                isInvalid={errors.description && touched.description}
                {...getFieldProps("description")}
              />

              <Input
                label="Stock Ticker"
                error={errors.stock_ticker}
                isInvalid={errors.stock_ticker && touched.stock_ticker}
                {...getFieldProps("stock_ticker")}
              />

              <hr />

              <Button size="sm" type="submit">
                Save &amp; Continue
              </Button>
            </form>
          )}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardProfile;
