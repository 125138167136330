import React from "react";
// import Drift from 'react-driftjs'
import Menu from "./menu";

const Layout = (props) => {
  return (
    <>
      <Menu />
      <div className="main-content pb-3">{props.children}</div>
    </>
  );
};

export default Layout;
