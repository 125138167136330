import { SUBMITTED } from "@equidefi/shared/constants/accreditation";
import { ApiClient } from "./ApiClient";

class FileUploadClient extends ApiClient {
  async getPresignedPublicUrl(filename: string, extension: string) {
    const response = await this.post(`/v2/files`, {
      filename,
      extension,
    });
    return response.data;
  }
}

export default FileUploadClient;
