import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Spinner from "../../../components/spinner";
import { searchBroker } from "../../../hooks/useBrokers";
import {
  addOfferingBroker,
  removeOfferingBroker,
  useOfferingBrokers,
} from "../../../hooks/useOfferings";
import { Link } from "react-router-dom";

const WizardBroker = (props) => {
  const { id } = useParams();
  const { data: brokers, isLoading: spinner, refetch } = useOfferingBrokers(id);
  const [selectedBroker, setSelectedBroker] = useState();

  const [modal, showModal] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Add a broker(s) to the offering.</h2>
        <div>
          <button
            className="btn btn-light"
            onClick={() => {
              showModal(true);
            }}
          >
            Add Broker
          </button>
        </div>
      </div>

      <hr />

      <Spinner show={spinner}>
        <div className="table-responsive">
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Company</th>
                <th>CRD Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {brokers?.map((broker, index) => {
                return (
                  <tr key={index}>
                    <td className="align-baseline">
                      {broker.first_name} {broker.last_name}
                    </td>
                    <td className="align-baseline">{broker.company}</td>
                    <td className="text-capitalize align-baseline">
                      {broker.crd_number}
                    </td>
                    <td className="text-end align-baseline">
                      <button
                        className="btn btn-sm btn-light ms-2"
                        onClick={() => {
                          removeOfferingBroker(id, broker.id)
                            .then(() => {
                              refetch();
                            })
                            .catch((error) => console.log(error));
                        }}
                      >
                        <i className="fe fe-trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Spinner>

      <Modal
        show={modal}
        onHide={() => showModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
      >
        <Modal.Body>
          <h1>Add Broker to Offering</h1>
          <AsyncSelect
            className="mt-2"
            type="select"
            name="type"
            title="Select a Broker"
            isSearchable={true}
            required={true}
            loadOptions={searchBroker}
            value={selectedBroker}
            onChange={setSelectedBroker}
          />
          <hr />
          <div className="mt-3">
            <button
              className="btn btn-eq-primary"
              type="submit"
              onClick={async () => {
                try {
                  await addOfferingBroker(id, selectedBroker.value.id);
                  showModal(false);
                  setSelectedBroker(null);
                  refetch();
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Save
            </button>
            <button
              className="btn btn-link ms-2"
              type="button"
              onClick={() => showModal(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <hr />
      <Link
        to={`/offering/${id}/wizard/calendar`}
        className="btn btn-eq-primary"
      >
        Save &amp; Continue
      </Link>
    </>
  );
};

export default WizardBroker;
