import React from "react";
import { NavLink } from "react-router-dom";

import { Tab, TabList, Tabs } from "@chakra-ui/react";
import items from "../../../data/nav_offering_wizard.json";

const WizardNav = ({ id, section }) => {
  const defaultIndex = items.findIndex((item) => item.path === section);

  return (
    <Tabs defaultIndex={defaultIndex} orientation="vertical">
      <TabList>
        {items.map((item) => (
          <Tab
            to={`/offering/${id}/wizard/${item.path}`}
            whiteSpace="nowrap"
            justifyContent="flex-start"
            as={NavLink}
            key={item.path}
          >
            {item.name}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default WizardNav;
