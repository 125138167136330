import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Heading,
  Modal,
  Button,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
  ModalHeader,
  ModalBody,
  Flex,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { ACCREDITATION_OPTIONS } from "@equidefi/shared/constants/accreditation";
import Select from "react-select";
import { UploadComponent } from "@equidefi/portals/components/accreditation/UploadComponent";
import { useUpdateVault } from "@equidefi/portals/hooks/useAccreditation";

const AccreditationUpload = ({ investment, onClose }) => {
  const toast = useToast();
  const [fileCount, setFileCount] = useState(1);
  const updateVault = useUpdateVault(investment.id);

  const onSubmit = async () => {
    try {
      await updateVault.mutateAsync(values);
      toast({
        status: "success",
        description: "Investment files were saved successfully",
      });
      onClose();
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        description: "Failed to submit your files",
      });
    }
  };

  const { handleSubmit, values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      accreditation_method: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  });

  const hasErrors = useMemo(() => {
    if (!values.accreditation_method) {
      return true;
    } else if (!values.doc_1_file) {
      return true;
    } else if (!values.utility_bill) {
      return true;
    }
    return false;
  }, [values]);

  if (updateVault.isLoading) {
    return (
      <Center h="100vh">
        <Spinner label="Saving files to investment vault..." />
      </Center>
    );
  }

  return (
    <Modal
      isOpen
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Accreditation Upload Override</ModalHeader>
        <ModalBody>
          <Text>
            Select an accreditation and upload files on behalf of this investor
            into the encrypted vault.
          </Text>
          <form onSubmit={handleSubmit}>
            <Select
              autoFocus={false}
              placeholder={"Select an Accreditation Method"}
              onChange={(value) => {
                setFieldValue("accreditation_method", value.value);
              }}
              options={ACCREDITATION_OPTIONS}
              components={{
                IndicatorSeparator: null,
              }}
            />
            <Heading fontWeight="bold" fontSize="1rem" mt={10}>
              Upload accreditation documents
            </Heading>
            {Array.from({ length: fileCount }).map((_, index) => (
              <Box mb={1}>
                <UploadComponent
                  investmentId={investment.id}
                  id={`document_upload_${index + 1}`}
                  handleChange={handleChange}
                  vaultField={`doc_${index + 1}_file`}
                  fileNum={index + 1}
                  key={index}
                />
              </Box>
            ))}
            {fileCount < 5 ? (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setFileCount(fileCount + 1);
                }}
                variant="outline"
                mt="2"
                justifyContent="start"
                colorScheme="gray"
                borderColor="equidefi.darkGray"
                h="12"
              >
                + Add additional file
              </Button>
            ) : null}
            <Flex align="center" gap="1" mt="2">
              <Heading fontWeight="bold" fontSize="1rem" mt={10}>
                Upload proof of residence
              </Heading>
            </Flex>
            <UploadComponent
              investmentId={investment.id}
              id="document_upload_utility"
              handleChange={handleChange}
              vaultField={`utility_bill`}
            />
            <Box mt={10} mb={50}>
              <Button
                className="btn btn-eq-primary"
                type="submit"
                isDisabled={hasErrors}
              >
                Save Files
              </Button>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AccreditationUpload;
