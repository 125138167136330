import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";

import { MESSAGE } from "../../../constants/forms";
import { useUpdateInvestment } from "@equidefi/portals/hooks/useInvestments";

import { DRAFT } from "@equidefi/shared/constants/investments";

// TODO: Reference values from Prisma types once DB package is extracted
const WorkflowEvent = [
  "PENDING",
  "SUBMITTED",
  "APPROVED",
  "REJECTED",
  "COMPLETED",
  "REVIEW",
  "CLOSED",
];

const statusOptions = WorkflowEvent.map((event) => ({
  label: event,
  value: event,
}));

const ModalUpdateStatus = ({
  investmentId,
  show = false,
  onSubmit = () => {},
  onHide = () => {},
}) => {
  const toast = useToast();
  const updateStatus = useUpdateInvestment(investmentId);

  const handleSubmit = async (data) => {
    try {
      await updateStatus.mutateAsync({
        status: data.status.value,
      });

      toast({
        status: "success",
        description: "Status successfully updated",
      });
      onSubmit(data);
    } catch (e) {
      toast({
        status: "error",
        description: "Sorry, the status could not be updated",
      });
    }
  };

  return (
    <Modal size="lg" isOpen={show} onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <span className="fs-2">Investment Status</span>
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          enableReinitialize
          initialValues={{
            status: statusOptions.find((so) => so.id === DRAFT),
          }}
          validationSchema={Yup.object().shape({
            status: Yup.object().required(MESSAGE.REQUIRED("status")),
          })}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ touched, errors, setFieldValue }) => (
            <Form>
              <ModalBody>
                <FormControl isInvalid={errors.status && touched.status}>
                  <FormLabel>Status</FormLabel>

                  <Select
                    autoFocus={false}
                    placeholder={"Select a Status"}
                    onChange={(value) => {
                      setFieldValue("status", value);
                    }}
                    options={statusOptions}
                    components={{
                      IndicatorSeparator: null,
                    }}
                  />
                  <FormErrorMessage>{errors.status}</FormErrorMessage>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button size="sm" type="submit">
                  Update
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ModalUpdateStatus;
