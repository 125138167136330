import { useMutation, useQuery } from "@tanstack/react-query";
import InvitationClient from "../clients/InvitationClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

/**
 * @param type
 * @param issuerId
 * @param {import("@tanstack/react-query").UseQueryOptions} options
 */
export const useInvitationList = (type, issuerId, options = {}) => {
  const invitationApi = useApi(InvitationClient);

  return useQuery(
    ["invitations"],
    async () => invitationApi.getInvitations(type, issuerId),
    options
  );
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useDeleteInvite = (options = {}) => {
  const invitationApi = useApi(InvitationClient);

  return useMutation(
    async ({ invitationId }) => invitationApi.deleteInvitation(invitationId),
    options
  );
};

/**
 * @param inviteId
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useVerifyInvite = (inviteId, options = {}) => {
  const invitationApi = useApi(InvitationClient);

  return useQuery(
    ["invitation", inviteId],
    async () => invitationApi.verify(inviteId),
    options
  );
};
