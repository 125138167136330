import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import swal from "sweetalert";

import { useCancelInvestmentAgreement } from "@equidefi/portals/hooks/useSignatures";

import InvestmentVoidModal from "./InvestmentVoidModal";

const VoidAgreementButton = ({ agreement, onVoided = () => {}, ...rest }) => {
  const cancelAgreement = useCancelInvestmentAgreement();
  const voidModal = useDisclosure();

  const handleClickVoidAgreement = async () => {
    const status = await swal({
      title: "Void Agreement",
      text: "Are you sure you want to cancel the current agreement?",
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (status) {
      voidModal.onOpen();
    }
  };

  const toast = useToast();

  const handleConfirmVoid = async ({ reason }) => {
    try {
      await cancelAgreement.mutateAsync({
        investmentId: agreement.investment_id,
        investmentAgreementId: agreement.id,
        reason: reason,
      });
      toast({
        status: "success",
        description: `The investment agreement has been voided.`,
      });
      onVoided();
    } catch (e) {
      toast({
        status: "error",
        description:
          e.response?.data?.errors?.join(", ") ??
          "Sorry, we could not void this agreement. Our team has been notified",
      });
    }
  };

  if (!agreement) {
    return null;
  }

  return (
    <>
      <Button
        isLoading={cancelAgreement.isLoading}
        isDisabled={agreement?.status === "COMPLETED"}
        onClick={handleClickVoidAgreement}
        colorScheme="red"
        {...rest}
      >
        Void Agreement
      </Button>

      <InvestmentVoidModal
        isOpen={voidModal.isOpen}
        onClose={voidModal.onToggle}
        onSubmit={handleConfirmVoid}
      />
    </>
  );
};

export default VoidAgreementButton;
