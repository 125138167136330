import { EXEMPTION_TYPES } from "@equidefi/shared/constants/offerings";

export const regulationLabels = {
  [EXEMPTION_TYPES.REG_A]: "Regulation A",
  [EXEMPTION_TYPES.REG_A_PLUS]: "Regulation A+",
  [EXEMPTION_TYPES.REG_S]: "Regulation S",
  [EXEMPTION_TYPES.REGULATION_CF]: "Regulation CF",
  [EXEMPTION_TYPES.SECTION_4A5]: "Section 4(a)(5)",
  [EXEMPTION_TYPES.SELF_UNDERWRITTEN]: "Self-Underwritten",
  [EXEMPTION_TYPES.REG_D_504_B]: "Regulation D - Rule 504(b)",
  [EXEMPTION_TYPES.REG_D_506_B]: "Regulation D - Rule 506(b)",
  [EXEMPTION_TYPES.REG_D_506_C]: "Regulation D - Rule 506(c)",
};

export const toExemptionOption = (val) => ({
  value: val,
  label: regulationLabels[val],
});

export const typeList = [
  { id: "1", name: "Common Stock" },
  { id: "4", name: "Convertible Notes" },
  { id: "2", name: "Units" },
  { id: "3", name: "Preferred Stock" },
  { id: "5", name: "Other Investment or Securities" },
  { id: "6", name: "Special Purpose Vehicle" },
];

export const documentCategories = [
  { id: "1", name: "Term Sheet" },
  {
    id: "2",
    name: "Confidential Offering Memorandum (or Wrapper for offering)",
  },
  { id: "3", name: "SEC Filings (most recent Annual and Quarterly Reports)" },
  {
    id: "4",
    name: "SEDAR Filings (Canadian issuers - most recent Annual and Quarterly Reports)",
  },
  {
    id: "5",
    name: "Subscription Agreement (Securities Purchase/Note Purchase Agreement as applicable)",
  },
  { id: "6", name: "Preferred Stock Certificate of Designation" },
  { id: "7", name: "Convertible/Non-Convertible" },
  { id: "8", name: "Form of Warrant" },
  { id: "9", name: "Certificate/Articles of Incorporation/By-Laws" },
  { id: "10", name: "Registration Rights Agreement" },
  { id: "11", name: "Investor Rights Agreement" },
  { id: "12", name: "Lockup/Leakout/Other agreements" },
  { id: "13", name: "Escrow Agreement" },
  { id: "14", name: "Other" },
];

export const sections = {
  ACCOUNT: "Account",
  OFFERING_INFO: "Additional Offering Info",
  CONTACTS: "Authorized Persons",
  PROFILE: "Profile",
  SUBSCRIPTION_INFO: "Subscription Info",
  EXEMPTIONS: "Exemptions",
  DOCUMENTS: "Investor Package",
  AGREEMENTS: "Execution Documents",
  CALENDAR: "Calendar",
};

export const STATUS = {
  "Invite Sent": "invite_sent",
  "Review Investor": "review_investor",
  "Investor Approved": "investor_approved",
  "Investor Rejected": "investor_rejected",
  "Agreement Signed": "agreement_signed",
  "Payment Pending": "payment_pending",
  "Payment Successful": "payment_successful",
  "Payment Failed": "payment_failed",
  "Agreement Fully Signed": "agreement_fully_signed",
  "Securities Issued": "securities_issued",
  Reconfirmation: "recon",
};

export const STOCK_TYPES = {
  common_stock: "Common Stock",
  convertible_note: "Convertible Notes",
  preferred_stock: "Preferred Stock",
  unit: "Units",
  safe: "SAFE",
  in_lieu_of_cash: "In Lieu of Cash",
};

export const STOCK_TYPE_OPTIONS = Object.entries(STOCK_TYPES).map(
  ([key, value]) => ({ value: key, label: value })
);
