import { useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import {
  useCurrentUser,
  useIdentifyLDUser,
} from "@equidefi/portals/hooks/useUsers";
import Routes from "./Routes";
import { isAdmin } from "./helpers";
import { CONFIG } from "./constants/config";
import MaintenanceScreen from "@equidefi/portals/components/MaintenanceScreen";

toast.configure();

const App = (props) => {
  const { data: me } = useCurrentUser();
  const admin = useMemo(() => isAdmin(me), [me]);

  useIdentifyLDUser(me);
  const flags = useFlags();


  axios.defaults.baseURL = CONFIG.EQ_API_URL;
  if (me?.token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${me?.token}`;
  }

  if (flags.maintenanceMode) return <MaintenanceScreen />;

  return <Routes isAdmin={admin} />;
};

export default App;
