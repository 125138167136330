import { useMemo } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Tag,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  INQUIRY_EXPIRED,
  INQUIRY_STATUS_ALERT_TYPE,
  INQUIRY_STATUS_TEXT,
} from "../../../constants/inquiries";
import { Icon } from "@equidefi/ui/icon";
import { personaInquiryLink } from "../../../helpers/persona";
import { Text } from "@equidefi/ui";
import { userFullName } from "@equidefi/shared";
import { useSendInquiryEmail } from "../../../hooks/inquiries";

const InquiryName = ({ inquiry }) => {
  if (!inquiry?.business_name && !inquiry?.first_name) return null;

  const isKYB = !!inquiry?.business_name;
  return (
    <Flex align="center" gap="2" w={{ base: "20%", sm: "40%" }}>
      <Box flexShrink="0">
        <Tooltip label={isKYB ? "Entity" : "Individual"}>
          {isKYB ? (
            <Icon.Briefcase size="1em" />
          ) : (
            <Icon.User size="1em" minW="1em" />
          )}
        </Tooltip>
      </Box>

      <Text mb="0" noOfLines={1} wordBreak="break-all">
        {isKYB ? inquiry.business_name : userFullName(inquiry)}
      </Text>
    </Flex>
  );
};

export const InquiryRow = ({ inquiry, canEdit }) => {
  const toast = useToast();
  const inquiryEmail = useSendInquiryEmail(inquiry.id, inquiry.investment_id);

  const sendInquiryLinkEmail = async () => {
    try {
      await inquiryEmail.mutateAsync();
      toast({
        status: "success",
        description: "Successfully sent new inquiry link to investor.",
      });
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description:
          e.response?.data?.errors?.join(". ") ?? "Something went wrong.",
      });
    }
  };

  const canSendEmail = useMemo(
    () => canEdit && inquiry.status === INQUIRY_EXPIRED,
    [inquiry.status, canEdit]
  );

  return (
    <Flex align="center" gap="2">
      <InquiryName inquiry={inquiry} />
      <Tag size="lg" colorScheme={INQUIRY_STATUS_ALERT_TYPE[inquiry.status]}>
        {INQUIRY_STATUS_TEXT[inquiry.status]}
      </Tag>
      {canEdit && (
        <HStack ml="auto">
          <Tooltip
            hasArrow
            placement="auto-start"
            label={
              "Inquiries can be approved or declined from a Completed or Needs Review state"
            }
          >
            <Box as={Icon.Info} color="gray.400" size="1em" />
          </Tooltip>

          {canSendEmail && (
            <Tooltip
              hasArrow
              placement="auto-start"
              label={"Send new inquiry link to investor"}
            >
              <IconButton
                size="xs"
                colorScheme="gray"
                onClick={sendInquiryLinkEmail}
                icon={<Icon.Mail size="1em" />}
              />
            </Tooltip>
          )}

          <Button
            as="a"
            size="xs"
            colorScheme="gray"
            href={personaInquiryLink(inquiry.inquiry_id)}
            rel="external noreferrer noopener"
            target="_blank"
          >
            View in Persona
          </Button>
        </HStack>
      )}
    </Flex>
  );
};
