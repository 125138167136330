import { BrowserRouter as Router, Switch } from "react-router-dom";

import AdminLayout from "./layouts/admin";
import PublicLayout from "./layouts/public";
import AppRoute from "./layouts/route";

import CompleteProfile from "./pages/auth/completeProfile";
import Forgot from "./pages/auth/forgot";
import Login from "./pages/auth/login";
import MFA from "./pages/auth/mfa";
import Reset from "./pages/auth/reset";

import OfferingList from "./pages/offering";
import OfferingCreate from "./pages/offering/create";
import OfferingOverview from "./pages/offering/overview";
import OfferingWizard from "./pages/offering/wizard";

import Investment from "./pages/offering/InvestmentOverview";
import OfferingInvestments from "./pages/offering/investors";

import BrokerList from "./pages/brokers";
import BrokerEditor from "./pages/brokers/editor";

import OfferingAgreementsLegacy from "./pages/offering/agreements";
import OfferingBrokers from "./pages/offering/brokers";
import BulkInvite from "./pages/offering/bulkInvite";
import OfferingContacts from "./pages/offering/contacts";
import OfferingDocuments from "./pages/offering/documents";
import OfferingAgreements from "./pages/offering/OfferingAgreements";
import OfferingSettings from "./pages/offering/settings";

import issuerList from "./pages/issuer";
import issuerEditor from "./pages/issuer/editor";
import issuerOfferings from "./pages/issuer/offerings";
import IssuerTeam from "./pages/issuer/team";

import MyProfile from "./pages/auth/profile";
import InvoiceList from "./pages/invoices";
import { Invoice } from "./pages/invoices/Invoice";
import Payment from "./pages/invoices/Payment";
import Success from "./pages/invoices/Success";
import MissingPage from "./pages/MissingPage";

import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import ScrollToTop from "./components/ScrollToTop";
import AccreditationList from "./pages/accreditation";
import InvestorDocuments from "./pages/accreditation/InvestorDocuments";
import Roles from "./pages/issuer/roles";
import AcceptInvitation from "./pages/issuer/team/AcceptInvitation";
import Invitations from "./pages/issuer/team/Invitations";
import OfferingAdmin from "./pages/offering/Admin";
import OfferingTranche from "./pages/offering/Tranche";
import OfferingTraunches from "./pages/offering/Traunches";

const Routes = ({ isAdmin }) => {
  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        {/* Uses a hook under the hook. Some nuance may be needed page-to-page */}
        <ScrollToTop />
        <Switch>
          <AppRoute exact path="/" layout={PublicLayout} component={Login} />
          <AppRoute exact path="/mfa" layout={PublicLayout} component={MFA} />
          <AppRoute
            exact
            path="/forgot"
            layout={PublicLayout}
            component={Forgot}
          />
          <AppRoute
            exact
            path="/reset/:id/:token"
            layout={PublicLayout}
            component={Reset}
          />
          <AppRoute
            exact
            path="/accept-invite/issuer/:token"
            layout={PublicLayout}
            component={AcceptInvitation}
          />
          <AppRoute
            exact
            path="/complete-profile/:token"
            layout={PublicLayout}
            component={CompleteProfile}
          />

          <AppRoute
            exact
            path="/brokers"
            layout={AdminLayout}
            component={BrokerList}
          />
          {isAdmin && (
            <AppRoute
              exact
              path="/brokers/create"
              layout={AdminLayout}
              component={BrokerEditor}
            />
          )}
          {isAdmin && (
            <AppRoute
              exact
              path="/brokers/:id/broker"
              layout={AdminLayout}
              component={BrokerEditor}
            />
          )}

          <AppRoute
            exact
            path="/issuer"
            layout={AdminLayout}
            component={issuerList}
          />
          {isAdmin && (
            <AppRoute
              exact
              path="/issuer/create"
              layout={AdminLayout}
              component={issuerEditor}
            />
          )}
          {isAdmin && (
            <AppRoute
              exact
              path="/issuer/:id/edit"
              layout={AdminLayout}
              component={issuerEditor}
            />
          )}
          <AppRoute
            exact
            path="/issuer/:id/offerings"
            layout={AdminLayout}
            component={issuerOfferings}
          />
          <AppRoute
            exact
            path="/issuer/:id/users"
            layout={AdminLayout}
            component={IssuerTeam}
          />
          <AppRoute
            exact
            path="/issuer/:issuerId/invitations"
            layout={AdminLayout}
            component={Invitations}
          />
          {isAdmin && (
            <AppRoute
              exact
              path="/issuer/:issuerId/roles"
              layout={AdminLayout}
              component={Roles}
            />
          )}

          <AppRoute
            exact
            path="/invoices"
            layout={AdminLayout}
            component={InvoiceList}
          />
          <AppRoute
            exact
            path="/accreditation"
            layout={AdminLayout}
            component={AccreditationList}
          />
          <AppRoute
            exact
            path="/accreditation/:id"
            layout={AdminLayout}
            component={InvestorDocuments}
          />
          <AppRoute
            exact
            path="/payment"
            layout={AdminLayout}
            component={Payment}
          />
          <AppRoute
            exact
            path="/payment/success"
            layout={AdminLayout}
            component={Success}
          />
          <AppRoute
            exact
            path="/invoice/:id"
            layout={AdminLayout}
            component={Invoice}
          />
          <AppRoute
            exact
            path="/offering"
            layout={AdminLayout}
            component={OfferingList}
          />
          <AppRoute
            exact
            path="/offering/create"
            layout={AdminLayout}
            component={OfferingCreate}
          />
          <AppRoute
            exact
            path="/offering/:id/edit"
            layout={AdminLayout}
            component={OfferingCreate}
          />
          <AppRoute
            exact
            path="/offering/:id/admin"
            layout={AdminLayout}
            component={OfferingAdmin}
          />
          <AppRoute
            exact
            path="/offering/:id/tranches"
            layout={AdminLayout}
            component={OfferingTraunches}
          />
          <AppRoute
            exact
            path="/offering/:id/agreements"
            layout={AdminLayout}
            component={OfferingAgreements}
          />
          <AppRoute
            exact
            path="/offering/:id/tranches/:trauncheId"
            layout={AdminLayout}
            component={OfferingTranche}
          />
          <AppRoute
            exact
            path="/offering/:id/wizard/:section"
            layout={AdminLayout}
            component={OfferingWizard}
          />
          <AppRoute
            exact
            path="/offering/:id/overview"
            layout={AdminLayout}
            component={OfferingOverview}
          />
          <AppRoute
            exact
            path="/offering/:id/investments"
            layout={AdminLayout}
            component={OfferingInvestments}
          />
          <AppRoute
            exact
            path="/offering/:id/investment/:investment_id/overview"
            layout={AdminLayout}
            component={Investment}
          />
          <AppRoute
            exact
            path="/offering/:id/contacts"
            layout={AdminLayout}
            component={OfferingContacts}
          />
          <AppRoute
            exact
            path="/offering/:id/brokers"
            layout={AdminLayout}
            component={OfferingBrokers}
          />
          <AppRoute
            exact
            path="/offering/:id/documents"
            layout={AdminLayout}
            component={OfferingDocuments}
          />
          <AppRoute
            exact
            path="/offering/:id/agreements_legacy"
            layout={AdminLayout}
            component={OfferingAgreementsLegacy}
          />
          <AppRoute
            exact
            path="/offering/:id/settings"
            layout={AdminLayout}
            component={OfferingSettings}
          />
          <AppRoute
            exact
            path="/offering/:id/bulk-invite"
            layout={AdminLayout}
            component={BulkInvite}
          />

          <AppRoute
            exact
            path="/profile"
            layout={AdminLayout}
            component={MyProfile}
          />
          <AppRoute
            exact
            path="*"
            layout={PublicLayout}
            component={MissingPage}
          />
        </Switch>
      </QueryParamProvider>
    </Router>
  );
};

export default Routes;
