/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    Box,
    Button,
    Flex,
    Link,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    useToast,
  } from "@chakra-ui/react";
import { usePDF } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../../layouts/header";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Heading, Paper, Text } from "@equidefi/ui";

import { PDFDocument } from "@equidefi/portals/components/PDF";
import { userFullName } from "@equidefi/shared";
import { paymentTypeDisplay } from "@equidefi/shared/helpers/investments";
import { Icon } from "@equidefi/ui/icon";
import TrauncheInvestmentModal from "../../components/offerings/TrauncheInvestmentModal";
import Spinner from "../../components/spinner";
import { STOCK_TYPES } from "../../constants";
import { CurrencyFormatter, NumberFormatter } from "../../constants/format";
import { isAdmin, isOfferingOwner } from "../../helpers";
import { useOfferingTranche } from "../../hooks/useOfferings";
import
  {
    DateTimeService,
    MomentDateTimeAdapter,
  } from "../../services/DateTimeService";
import { ClosingCertificatePdf } from "./pdfs/ClosingCertificate";
import { TailPdf } from "./pdfs/Tail";

const dateFormat = new DateTimeService(new MomentDateTimeAdapter());

const CertificateSummaryDataPoint = ({ label, value }) => {
  return (
    <li className="list-group-item px-4 d-flex justify-content-between w-100 align-items-center">
      <strong style={{ maxWidth: "75%" }}>{label}</strong>
      <span>{value}</span>
    </li>
  );
};

const OfferingTranche = () => {
  const { trauncheId: id, id: offeringId } = useParams();
  const { data: user } = useCurrentUser();
  const { data: tranche, isLoading } = useOfferingTranche(id, offeringId);
  const { offering, issuer, investments, summary } = tranche ?? {};

  const [formDSpinner, showFormDSpinner] = useState(false);
  const [csvSpinner, setCsvSpinner] = useState(false);
  const [currentInvestor, setCurrentInvestor] = useState(null);
  const offeringApi = useApi(OfferingClient);

  const toast = useToast();

  const canDownload = useMemo(
    () => isAdmin(user) || isOfferingOwner(user, offeringId),
    [user, offeringId]
  );

  const downloadFile = async (documentType, filename) => {
    const typeMap = {
      csv: "data:text/csv",
      formd: "data:application/pdf;base64",
      certificate: "data:application/pdf;base64",
      tail: "data:application/pdf;base64",
    };

    try {
      const data = await offeringApi.getTrauncheDocument(
        id,
        offeringId,
        documentType
      );

      /** @type {Blob | string} */
      const content =
        documentType === "csv"
          ? new Blob(data.split("\n"), { type: "text/csv;charset=utf-8" })
          : `${typeMap[documentType]},${data.data}`;

      FileSaver.saveAs(content, filename);
    } catch (e) {
      toast({
        status: "error",
        description: `Sorry, we are having trouble downloading ${filename}`,
      });
    }
  };

  const downloadFormD = async () => {
    showFormDSpinner(true);
    await downloadFile("formd", `Offering Form D - ${offering.name}.pdf`);
    showFormDSpinner(false);
  };

  const downloadCSVExport = async () => {
    setCsvSpinner(true);
    await downloadFile(
      "csv",
      `Offering Investors Export - ${offering.name}.csv`
    );
    setCsvSpinner(false);
  };

  const ClosingDocument = useMemo(() => {
    if (!issuer || !canDownload) return <PDFDocument />;

    return (
      <ClosingCertificatePdf
        issuer={issuer}
        offering={offering}
        investments={investments}
        summary={summary}
      />
    );
  }, [issuer, canDownload]);

  const TailDocument = useMemo(() => {
    if (!issuer || !canDownload) return <PDFDocument />;

    return (
      <TailPdf
        issuer={issuer}
        offering={offering}
        quantity={summary.total_security_type_sold}
        investments={investments}
        unitPrice={offering.unit_price}
      />
    );
  }, [issuer, canDownload]);

  const [certificate, updateCertificate] = usePDF({
    document: ClosingDocument,
  });
  const [tail, updateTail] = usePDF({ document: TailDocument });

  useEffect(() => {
    if (ClosingDocument) updateCertificate(ClosingDocument);
    if (TailDocument) updateTail(TailDocument);
  }, [TailDocument, ClosingDocument]);

  if (isLoading) {
    return <Spinner show />;
  }

  return (
    <>
      <Header
        name="Closing Tranche Summary"
        backTo={[
          `/offering/${offeringId}/tranches`,
          "Back to Closing Tranches",
        ]}
        title={offering?.name}
      >
        <Tooltip
          label="You don't have permission to download closing certificate"
          hasArrow
        >
          <Button
            as={Link}
            href={canDownload && certificate.url}
            download={`Closing Tranche Summary - ${offering?.name}`}
            isLoading={certificate.loading}
            _hover={{
              textDecor: "none",
            }}
            isDisabled={!investments?.length || !issuer || !canDownload}
          >
            Print Certificate
          </Button>
        </Tooltip>
      </Header>

      {/* {offering && <PDFViewer height="800px" width="100%"></PDFViewer>} */}

      <Box className="container-fluid">
        <div className="row">
          <h3>Summary</h3>
          <div className="col-12 col-lg-6">
            <div className="card">
              <ul className="list-group list-group-flush">
                <CertificateSummaryDataPoint
                  label="Issuer Name"
                  value={issuer?.name}
                />
                <CertificateSummaryDataPoint
                  label="Launch Date"
                  value={dateFormat.formatMonthDayYear(offering?.launch_date)}
                />
                <CertificateSummaryDataPoint
                  label="Closing Date"
                  value={dateFormat.formatMonthDayYear(summary?.closing_date)}
                />
                <CertificateSummaryDataPoint
                  label="Total Closing Amount"
                  value={CurrencyFormatter.format(
                    summary?.total_closing_amount
                  )}
                />
                <CertificateSummaryDataPoint
                  label={`Total ${
                    STOCK_TYPES[offering?.stock_type] ?? offering?.stock_type
                  } Sold`}
                  value={NumberFormatter.format(
                    summary?.total_security_type_sold ?? 0
                  )}
                />
                <CertificateSummaryDataPoint
                  label="Number of Investors"
                  value={investments?.length ?? 0}
                />
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-header-title">Documents</h3>
              </div>
              <div className="card-body p-0">
                <ul className="list-group list-group-flush">
                  <CertificateSummaryDataPoint
                    label="Transfer Agent Instruction Letter (TAIL)"
                    value={
                      <Button
                        as={Link}
                        href={canDownload && tail.url}
                        isLoading={tail.loading}
                        download={`Offering TAIL - ${offering?.name}.pdf`}
                        colorScheme="gray"
                        size="xs"
                        _hover={{
                          textDecor: "none",
                        }}
                        isDisabled={!canDownload}
                      >
                        Download
                      </Button>
                    }
                  />
                  <CertificateSummaryDataPoint
                    label="Form D"
                    value={
                      <Button
                        isLoading={formDSpinner}
                        isDisabled={!canDownload}
                        colorScheme="gray"
                        size="xs"
                        onClick={() => downloadFormD()}
                      >
                        Download
                      </Button>
                    }
                  />
                  <CertificateSummaryDataPoint
                    label="Export Investors"
                    value={
                      <Button
                        isLoading={csvSpinner}
                        isDisabled={!canDownload}
                        colorScheme="gray"
                        size="xs"
                        onClick={() => downloadCSVExport()}
                      >
                        Download
                      </Button>
                    }
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ pageBreakAfter: "always" }} />

        <Heading as="h3" size="md">
          Closed Investors
        </Heading>
        <Paper>
          <TableContainer>
            <Table size="lg" variant="simple" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th textAlign="center">Accredited</Th>
                  <Th textAlign="right">Amount</Th>
                  <Th textAlign="center">Unit Quantity</Th>
                  <Th textAlign="left">Fund Method</Th>
                  <Th textAlign="left">Funding Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {investments?.map((investment) => {
                  return (
                    <Tr
                      cursor="pointer"
                      onClick={() => setCurrentInvestor(investment)}
                      key={investment.id}
                    >
                      <Td verticalAlign="middle">
                        <Text mb={0}>{userFullName(investment.user)}</Text>
                        <Flex color="gray.400" align="center" gap={2}>
                          <Icon.Mail size=".85em" />
                          <Link
                            href={`mailto:${investment.user.email}`}
                            rel="external noreferrer noopener"
                            target="_blank"
                            textDecor="underline"
                          >
                            {investment.user.email}
                          </Link>
                        </Flex>
                      </Td>
                      <Td textAlign="center" verticalAlign="middle">
                        {investment.bad_actor ? "No" : "Yes"}
                      </Td>
                      <Td textAlign="right" verticalAlign="middle" isNumeric>
                        {CurrencyFormatter.format(investment.amount)}
                      </Td>
                      <Td textAlign="center" verticalAlign="middle">
                        {NumberFormatter.format(investment.quantity)}
                      </Td>
                      <Td
                        textAlign="left"
                        textTransform="capitalize"
                        verticalAlign="middle"
                      >
                        {investment.payments
                          ?.map((p) => paymentTypeDisplay(p))
                          ?.join("/")}
                      </Td>
                      <Td textAlign="left" verticalAlign="middle">
                        {dateFormat.formatReadableTimestamp(
                          investment.funding_date
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <TrauncheInvestmentModal
        investment={currentInvestor}
        onHide={() => setCurrentInvestor(null)}
      />
    </>
  );
};

export default OfferingTranche;
