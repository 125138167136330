import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import UserToken from "@equidefi/portals/clients/UserToken";

const DEFAULT_MESSAGE = "You must be logged in to access this page.";

export const useRequireUser = ({ destination = "/", onMount = true } = {}) => {
  const token = UserToken.token();
  const dispatch = useDispatch();

  const history = useHistory();

  const clearSession = useCallback(() => {
    localStorage.clear();

    dispatch({ type: "user", payload: {} });
    dispatch({ type: "issuer", payload: {} });
    dispatch({ type: "investment", payload: {} });
    dispatch({ type: "investor", payload: {} });
    dispatch({ type: "offering", payload: {} });
    dispatch({ type: "email", payload: "" });
    dispatch({ type: "token", payload: "" });
  }, [dispatch]);

  const triggerLogout = useCallback(
    ({ type = "error", message = DEFAULT_MESSAGE } = {}) => {
      clearSession();

      const toastFn = toast[type] ?? toast.error;
      toastFn(message);

      history.push(destination);
    },
    [history, destination, clearSession]
  );

  useEffect(() => {
    if (onMount && !token) {
      triggerLogout();
    }
  }, []);

  return triggerLogout;
};
