import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik } from "formik";
import { EXEMPTION_TYPES } from "@equidefi/shared/constants/offerings";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { updateOffering } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";
import { toExemptionOption } from "../../../constants";

const WizardExemptions = (props) => {
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const handleSubmit = async (data) => {
    showSpinner(true);
    data.investor_minimum_units =
      data.investor_minimum_amount / data.unit_price;
    await updateOffering(offering.id, data);
    refetch();
    showSpinner(false);
    history.push(`/offering/${offering.id}/wizard/documents`);
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Exemption Type</h2>
      <hr />

      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values }) => {
            const options = Object.keys(EXEMPTION_TYPES).map((x) =>
              toExemptionOption(x)
            );

            const value = values.exemption_type
              ? options.find((x) => x.value === values.exemption_type)
              : null;
            return (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="select"
                  name="exemption_type"
                  title="Exemption"
                  options={options}
                  value={value}
                  onChange={handleChange}
                />

                <hr />
                <button className="btn btn-eq-primary" type="submit">
                  Save &amp; Continue
                </button>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardExemptions;
